import React, { useEffect, useState } from "react";
import { Modal, Row } from "reactstrap";
import { Api } from "../../../api";
import { FaAngleDown, FaAngleRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import Info from "./info";
import { ExitModal } from "../../components/exitModal";
export default function MainSection({ id, setDetalhes }) {
  const [dados, setDados] = useState([]);
  const [fornecedor, setFornecedor] = useState([])

  useEffect(() => {
    handleRamoPai()
    setItem(id)
  }, []);
  const [modalRamo, setModalRamo] = useState(false);
  const [ramo, setRamo] = useState(false);
  const [dadosNew, setDadosNew] = useState(false);
  const [pai, setPai] = useState();
  const [escolha, setEscolha] = useState(1);
  const [carregado, setCarregado] = useState(false);

  const handleRamoPai = async () => {
    let response = await Api.ramosPaiAtuacao()
    setPai(response.list);
  }
  const [item2, setItem] = useState("");
  const handler = (id) => {
    if (Number(id) == Number(item2)) {
      setItem('')
    } else {
      setItem(id)
    }
  };
  useEffect(() => {
    let dados = pai?.find((item) => Number(item.id) == Number(item2))
    setDetalhes(dados)
    setTimeout(() => {
      let compoment = document.querySelector(`a[id="${id}"] .sub-menu .feature-item`)
      if (compoment) {
        if (compoment.style.display != "none") {
          let focar = document.querySelector(`a[id="${id}"]`)
          if (focar) {
            focar.focus()
            focar.scrollIntoView({ behavior: 'smooth', block: "center" });
          }
        }
      }
    }, 2500);




  }, [item2, pai])

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
}})
  return (
    <section className="portfolio-section " >
      <div className="container">

        <div className=" align-items-center portfolio-wrap" style={{ display: 'flex', flexWrap: 'wrap',justifyContent:!isDesktop?'center':'center'}}>

          {pai?.length > 0 && pai.map((item, index) => (
            <a tabIndex={-1} id={item.id} key={item.id} style={{ cursor: "pointer", marginBottom: 0}} >
              <div key={item?.id} >
                <a href={`/ramos/${item.id}`} style={{ margin: '25px 15px 15px', boxShadow: ' 1px 4px 4px 1px #09214040', borderRadius: 10, display: 'flex', flexDirection: "column", justifyContent: 'space-between', alignItems: 'center', height: 180, border: '0.01px solid #ccc' }} >
                  <div style={{ height: 115 }}>
                    <img src={`https://server.corpar.com.br/imagens/${item?.imagem ? item?.imagem : item?.icone}`} style={{ height: 115 }} />
                  </div>
                  <div style={{ height: 115, display: 'flex', justifyContent: 'flex-end' }}>
                    <p style={{ textAlign: 'center', fontSize: 16, color: '#092140', width: 160, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: 1.4 }}>
                      {item.nome.split('\n').map((linha, index) => (
                        <React.Fragment key={index}>
                          {linha}
                          <br />
                        </React.Fragment>
                      ))}
                    </p>
                  </div>
                </a>
              </div>
            </a>
          ))}
          
        </div>
      </div>


      <Modal
        id="modal-center3"
        size="md"
        isOpen={modalRamo}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Ramos de Atuação</h5>
        
          <ExitModal setModal={setModalRamo} />
        </div>
        <div className="modal-body">

          <Row style={{ padding: 5, height: '100%' }}>

            {dadosNew?.operadoraslinks && dadosNew?.operadoraslinks.length > 0 && dadosNew?.operadoraslinks.map((item2, index) => (
              <>
                <div className="col-6" style={{ textAlign: "center", fontSize: 12 }}>
                  <a href={item2.link} rel="noreferrer" target="_blank" >
                    <img
                      src={`https://server.corpar.com.br/imagens/${item2.tipooperadora?.imagem}`}
                      alt=""

                      width={'80%'}
                    />
                  </a>

                  <a href={item2.link} rel="noreferrer" target="_blank" > <p style={{ marginTop: '-10px' }}>{item2?.tipooperadora?.nome}</p></a>
                </div>
              </>
            ))}

            {!dadosNew?.operadoraslinks || dadosNew?.operadoraslinks.length == 0 && dadosNew?.tiposoperadoras.map((item2, index) => (
              <>
                <div className="col" style={{ textAlign: "center", fontSize: 12 }}>
                  <a href={dadosNew?.linkvenda} rel="noreferrer" target="_blank" >
                    <img
                      src={`https://server.corpar.com.br/imagens/${item2.tipooperadora?.imagem}`}
                      alt=""

                      width={'40%'}
                    />
                  </a>

                  <a href={dadosNew?.linkvenda} rel="noreferrer" target="_blank" ><p style={{ marginTop: '-10px' }}>{item2?.tipooperadora?.nome}</p></a>
                </div>
              </>
            ))}

          </Row>
        </div>
      </Modal>
    </section>
  );
}

import axios from "axios";
import { baseUrlApi } from './baseUrl'
const token = localStorage.getItem("authToken");

const axiosInstance = axios.create({
  baseURL: `${baseUrlApi}/usuarios`,
  headers: {
    'Authorization': `Bearer ${token}`,
    'ContentType': 'application/json'
  }

});
export const Api = {
  editar: async (dados) => {
    let response = await axiosInstance.post("/editPerfil", dados);
    return response.data;
  },
  deletar: async (dados) => {
    let response = await axiosInstance.delete(`/delete/${dados.id}`);
    return response.data;
  }
};

import React, { useEffect, useState } from "react";
import { Modal, Row } from "reactstrap";
import { Api } from "../../../api";
import { ExitModal } from "../../components/exitModal";
export default function MainSection({ datas ,ramo}) {
  const [dados, setDados] = useState([]);

  useEffect(() => {
    handleOperadora()
  }, [datas]);
  const [modalRamo, setModalRamo] = useState(false);
  const [dadosNew, setDadosNew] = useState(false);
  const [fornecedorSelect,setFornecedorSelect] = useState('')
  const [ramoSelect,setRamoSelect] = useState('')
  const handleOperadora = async () =>{
    let response = await Api.operadorasAll(ramo)
  
    if (response?.list?.length > 0) {
      let listagem = []
      let operadoras = []
      response.list.filter((item)=>item.ocultargeral==1).map((item) => {
        if (!listagem.includes(item.arquivo)) {
          listagem.push(item.arquivo)
          operadoras.push(item)
        }
      })
    
     operadoras.filter((item)=>item.ocultargeral==1).sort((a,b)=>a.ordemgeral - b.ordemgeral)
      setDados(operadoras)
    }

    // setDados(response.list);
  }
  return (
    <section className="portfolio-section ">
      <div className="container" style={{maxWidth:'1200px'}}>
        
        <div className="row align-items-center portfolio-wrap"  >
          {dados?.length>0 ? dados.sort((a,b)=>a.ordemgeral - b.ordemgeral)?.map((item) => (
              <a key={item.id} href={`/${item.subdominio}`} className={`col-lg-3 col-sm-6 item `} style={{ cursor: "pointer" }} >
                <div style={{ cursor: "pointer"}} >
                  <div className="feature-content style-three" style={{ textAlign: "center", marginTop: "auto", height: 130 }}  >
                    <img src={`https://server.corpar.com.br/imagens/${item?.imagem?item.imagem:item?.icone}`} alt="" height={100} />
                  </div>
                </div>
              </a>
            )): 
            <>
              <a className={`col-lg-12 col-sm-12 item `} style={{ cursor: "pointer" }} >
                <div style={{ cursor: "pointer"}} >
                  <div className="feature-content style-three" style={{ textAlign: "center", marginTop: "auto", height: 130 }}  >
                    Aguarde, breve novidades
                  </div>
                </div>
              </a>
            </>}
        </div>
      </div>
      <Modal id="modal-center3" size="md" isOpen={modalRamo} centered={true}>
        <div className="modal-header">
          <h5 className="modal-title mt-0">Ramos de Atuação</h5>
  
          <ExitModal setModal={setModalRamo} />
        </div>
        <div className="modal-body">
          <Row style={{ padding: 5, height: '100%' }}>
            {dadosNew?.operadoraslinks && dadosNew?.operadoraslinks.length > 0 && dadosNew?.operadoraslinks.map((item2, index) => (
              <>
                <div className="col-6" style={{ textAlign: "center", fontSize: 10 }}>
                  <a href={item2.link} rel="noreferrer" target="_blank" >
                    <img
                      src={`https://server.corpar.com.br/imagens/${item2.tipooperadora?.imagem}`}
                      alt=""

                      width={'80%'}
                    />
                  </a>
                  <a href={item2.link} rel="noreferrer" target="_blank" > <p style={{ marginTop: '-10px' }}>{item2?.tipooperadora?.nome}</p></a>
                </div>
              </>
            ))}
            {!dadosNew?.operadoraslinks || dadosNew?.operadoraslinks.length == 0 && dadosNew?.tiposoperadoras.map((item2, index) => (
              <>
                <div className="col" style={{ textAlign: "center", fontSize: 10 }}>
                  <a href={dadosNew?.linkvenda} rel="noreferrer" target="_blank" >
                    <img
                      src={`https://server.corpar.com.br/imagens/${item2.tipooperadora?.imagem}`}
                      alt=""

                      width={'40%'}
                    />
                  </a>
                  <a href={dadosNew?.linkvenda} rel="noreferrer" target="_blank" ><p style={{ marginTop: '-10px' }}>{item2?.tipooperadora?.nome}</p></a>
                </div>
              </>
            ))}
          </Row>
        </div>
      </Modal>
    </section>
  );
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Dados from "./index";
import Layouts from "../Corpo/Layouts";
import { Api } from "../../api";
export default function ServiceOne() {
  const { id} = useParams();
  const [operadora,setOperadora] = useState('')
  useEffect(()=>{
    handleOperadora()
  },[id])
const handleOperadora = async () => {
 
  let list = await Api.operadora(id);
  let valores = list.list.map((item) => {
    return { id: item.id, title: item.nome, image: item.imagem, filter: item.tipopessoa, descricao: item.descricao };
  });
  setOperadora(valores[0]?.title)
};
  return (
    <Layouts
      style={{ fontFamily: "Open Sans, sans-serif" ,textTransform:'none'}}
      pageTitle={operadora}
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Empresas Parceiras", path: "/empresas" },
        { name: operadora, path: `/empresas/${id}` },
      ]}>
      <Dados id={id} setOperadora={setOperadora}  />
    </Layouts>
  );
}

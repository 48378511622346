import React, { useEffect, useRef, useState } from "react";
import { Api } from "../../../api";
import SliderCom from "../../../components/helpers/SliderCom";
import { MdArrowBackIos } from "react-icons/md";
import { MdArrowForwardIos } from "react-icons/md";

export default function Ramos({ list }) {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth > 768);
        };
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [list]);

    const sliderRef = useRef(null);
    const settings = {
        dots: false,infinite: true,autoplay: true,autoplaySpeed: 2000,arrows: false,speed: 1000,
        slidesToShow: list?.length<=4?list?.length:4,
        slidesToScroll: 1,
        responsive: [
            { breakpoint: 992,settings: {slidesToShow: 4}},
            { breakpoint: 767,settings: {slidesToShow: 3}},
            { breakpoint: 575,settings: {slidesToShow: 1}}
        ]
    }
    const prevHandler = () => sliderRef.current.slickPrev();
    const nextHandler = () => sliderRef.current.slickNext();

   
    const [isHovered, setIsHovered] = useState(false);
    const [isHoveredVerMais, setIsHoveredVerMais] = useState(false);
    const handleMouse = () => {
        setIsHoveredVerMais(!isHoveredVerMais);
    };
    const handleMouseEnter = () => {
        setIsHovered(true);
    };
    const handleMouseLeave = () => {
        setIsHovered(false);
    };
    const textStyle = {
        textDecoration: isHovered ? 'underline' : 'none',
        cursor: 'pointer'
    };
    const textStyleVerMais = {
        textDecoration: isHoveredVerMais ? 'underline' : 'none',
        cursor: 'pointer'
    };
    return (
        <section className=""  style={{marginTop:20}}>
            <div className="" style={{ textAlign: 'center', marginBottom: 0 }}  >
                <div className="" >
                   
                    <div className="" style={{  display: 'flex' ,marginTop:'-60px'}} >

                        <div className="" style={{ margin: 'auto', width: '10%' }}>
                            <MdArrowBackIos onClick={prevHandler} color="#ccc" size={20} />
                        </div>
                        <div className=" " style={{ width: '90%' }} >
                            <div className="logo-carousel-wrap " >
                                <SliderCom settings={settings} selector={sliderRef}>
                                    {list && list.map((item, index) => (
                                        <a href={`/ramos/${item.id}`} tabIndex={-1} id={item.id} key={item.id} style={{ cursor: "pointer", marginBottom: 0 }} >
                                            <div key={item?.id} style={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }} >
                                                <a style={{ margin: '25px 10px 10px', borderRadius: 10, display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center', height: 200, width:'100%' }} >
                                                    <div style={{ height: 80 }}>
                                                        <img src={`https://server.corpar.com.br/imagens/${item?.imagem ? item?.imagem : item?.icone}`}  style={{ height: 60 }} />
                                                    </div>
                                                    <div style={{ height: 50, display: 'flex', justifyContent: 'flex-end' }}>
                                                        <p style={{ textAlign: 'center', fontSize: 16, color: '#092140', width: 200, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: 1.4, padding: 5 }}>
                                                            {item.nome.split('\n').map((linha, index) => (
                                                                <React.Fragment key={index}>
                                                                    {linha}
                                                                    <br />
                                                                </React.Fragment>
                                                            ))}
                                                        </p>
                                                    </div>
                                                </a>
                                            </div>
                                        </a>
                                    ))}
                                </SliderCom>
                            </div>
                        </div>
                        <div className="" style={{ margin: 'auto', width: '10%' }}>
                            <MdArrowForwardIos onClick={nextHandler} color="#ccc" size={20} />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { Api } from "../../api/apiCotacoes";
import { Filtros } from "./utils/filtros";
import { Visualizar } from "./utils/visualizar";
import { VisualizarDetalhe } from "./utils/visualizarDetalhes";
import { VisualizarSelecionado } from "./utils/visualizarSelecionado";
import { useParams } from "react-router-dom";
import Layouts from "../Corpo/Layouts";
import Icone from "../../assets/icones/icone.png"
import { useCookies } from 'react-cookie';

function PageCotacoes() {
    const { id, idOperadora } = useParams();
    document.title = "Corretor Parceiro";
    const [ramo, setRamo] = useState("");
    useEffect(() => {
        var url = window.location.href;
        url = url.split("/");
        if (url.includes("saude")) {
            setRamo("2");
        } else if (url.includes("odontologico")) {
            setRamo("1");
        }
    }, []);

    const [resposta, setResposta] = useState(false);
    const [error, setError] = useState(false);
    const [msg, setMsg] = useState("");
    const [dados, setDados] = useState();
    const [info, setInfo] = useState();
    const [estados, setEstados] = useState([]);
    const [areaComercializacao, setAreaComercializacao] = useState([]);
    const [modalVisualizarDetalhe, setModalVisualizarDetalhe] = useState(false);
    const [profissoes, setProfissoes] = useState();
    const [profissoesSelect, setProfissoesSelect] = useState();
    const [salvarVisualizar, setSalvarVisualizar] = useState(false);
    const [loading, setLoading] = useState(false);
    // const [id, setid] = useState("");
    const [modalExcluir, setModalExcluir] = useState(false);
    const [modalVisualizarOperadora, setModalVisualizarOperadora] =
        useState(false);
    const [buscar, setBuscar] = useState("");
    const [list, setList] = useState([]);
    const [cookie,setCookie] = useCookies(['authUser'])
    const obj =cookie.authUser


    useEffect(() => {
        if (obj?.dados?.estado) {
            let ids = estados?.find((item) => item.nome == obj?.dados?.estado)
            validation.setFieldValue('estados', ids?.id)
        }

    }, [obj, estados])
    const [editar, setEditar] = useState(false);
    const [itens, setItens] = useState([{}]);
    const [faixas, setFaixas] = useState([]);
    const [calculo, setCalculo] = useState([]);
    const [calculoDetalhe, setCalculoDetalhe] = useState([]);
    const [infoDependente, setInfoDependente] = useState([]);
    const [infoSelecionado, setInfoSelecionado] = useState({});
    const [
        modalVisualizarDetalheSelecionado,
        setModalVisualizarDetalheSelecionado
    ] = useState(false);
    const [modalEditar, setModalEditar] = useState(false);
    const [modalFiltro, setModalFiltro] = useState(false);
    const [modalAgendar, setModalAgendar] = useState(false);
    const [modalAgendamento, setModalAgendamento] = useState(false);
    const [municipios, setMunicipios] = useState([]);
    const [filtro1, setFiltro1] = useState("");
    const [filtro2, setFiltro2] = useState("");
    const [filtro3, setFiltro3] = useState("");
    const [filtro4, setFiltro4] = useState("");
    const [filtro5, setFiltro5] = useState("");
    const [filtro6, setFiltro6] = useState("");
    const [carregamento, setCarregamento] = useState(true)
    useEffect(() => {
        Listagem();
        handleEstados();
        handleProfissoes();
        handleFaixaValue();
        handleAreaComercializacao();
        handleMunicipios();
    }, []);
    const handleMunicipios = async () => {
        let option = await Api.municipio();
        setMunicipios(option.option);
    };
    const handleCarregamento = (status) => {
      
        let body = document.querySelector("body");
        if (status) {
            if (body) {
                body.style.cursor = "wait";
            }
        } else {
            if (body) {
                body.style.cursor = "";
            }
        }
    };
    const handleStatus = async (id, status) => {
        let list = await Api.status({ id: id, status });
        setList(list.list);
    };
    const handleAreaComercializacao = async () => {
        let dados = await Api.areacomercializacao();
        setAreaComercializacao(dados.option);
    };
    const handleEstados = async () => {
        let option = await Api.estados();
        setEstados(option);
    };
    const handleProfissoes = async () => {
        let dados = await Api.profissoes();
        let val = dados.option;
        val?.unshift({ nome: "Sem profissão", id: "" });
        setProfissoes(val);
        setProfissoesSelect([]);
    };
    const handleModifica = (index, val) => {
        let item = itens;
        if (item[index] || item[index] == "") {
            item[index] = val;
        } else {
            item.push(val);
        }
        setItens(item);
        setEditar(!editar);
    };
    const handleAdd = (val) => {

        let item = [...itens];
        item.push({});
        setItens(item);

    };
    const handleExit = (index) => {
        let item = [...itens];
        item.splice(index, 1);
        setItens(item);
        setEditar(!editar);
    };
    const handleFaixasValue = (value, index) => {
        let faixa = faixas;
        faixa[index].value = value;
        setFaixas(faixa);
        setEditar(!editar);
    };
    const handleFaixaValue = async () => {
        let list = await Api.faixas({ id: 8 });
        let faixasnew = list;
        setFaixas(faixasnew);
    };
    const handleTipoValue = async (dados) => {
        handleModifica(dados.item, dados.val);
    };
    const handleOperadora = async (idoperadora, produtos) => {
        handleCarregamento(true);
      
        let dados = await Api.calculoDetalhe({
            id: idoperadora,
            idcotacao: id,
            produtos: produtos.ids
        });
        // console.log({dados})
        gerarTabela(dados.list, dados.array);
    };
    const gerarTabela = (dados, dependentes) => {
        let array = {
            produto: [],
            faixas: [],
            observacoes: [],
            areacomercializacao: [],
            dependente: [],
            documento: [],
            pagamento: [],
            rede: [],
            reembolso: [],
            carencia: [],
            entidades: [],
            imagem: dados?.imagem,
            operadora: dados?.nome
        };
        dados?.tabelas?.map((item) => {

            array.produto.push({
                id: item.id,
                nome: item.nome,
                cobertura: item.cobertura,
                acomodacao: item.acomodacao,
                coparticipacao: item.coparticipacao,
                percentual: item.percentual,
                vidas: `${item.minvidas} a ${item.maxvidas}`,
                faixas: item.tabelafaixas.sort(
                    (pessoa1, pessoa2) => pessoa1.id - pessoa2.id
                ),
                precovida: item.precovida,
                item,
                status: item.status
            });

            if (
                item.observaco?.id &&
                !array.observacoes.some((arr) => arr.id == item.observaco.id)
            ) {
                array.observacoes.push({
                    id: item.observaco?.id,
                    item: item.observaco,
                    produto: [item.nome]
                });
            } else {
                array.observacoes.some((arr) =>
                    arr.id == item.observaco?.id ? arr.produto.push(item.nome) : ""
                );
            }
            if (
                item.areacomercializacao?.id &&
                !array.areacomercializacao.some(
                    (arr) => arr.id == item.areacomercializacao?.id
                )
            ) {
                array.areacomercializacao.push({
                    id: item.areacomercializacao.id,
                    produto: [item.nome],
                    municipios: item.areacomercializacaoestadosmunicipios
                });
            } else {
                array.areacomercializacao.some((arr) =>
                    arr.id == item.areacomercializacao?.id
                        ? arr.produto.push(item.nome)
                        : ""
                );
            }
            if (
                item.dependente?.id &&
                !array.dependente.some((arr) => arr.id == item.dependente?.id)
            ) {
                array.dependente.push({
                    id: item.dependente?.id,
                    item: item.dependente,
                    produto: [item?.nome]
                });
            } else {
                array.dependente.some((arr) =>
                    arr.id == item.dependente?.id ? arr.produto.push(item.nome) : ""
                );
            }
            if (
                item.documento?.id &&
                !array.documento.some((arr) => arr.id == item.documento?.id)
            ) {
                array.documento.push({
                    id: item.documento.id,
                    item: item.documento,
                    produto: [item.nome]
                });
            } else {
                array.documento.some((arr) =>
                    arr.id == item.documento?.id ? arr.produto.push(item.nome) : ""
                );
            }

            if (
                item.pagamento?.id &&
                !array.pagamento.some((arr) => arr.id == item.pagamento?.id)
            ) {
                array.pagamento.push({
                    id: item.pagamento.id,
                    item: item.pagamento,
                    produto: [item.nome]
                });
            } else {
                array.pagamento.some((arr) =>
                    arr.id == item.pagamento?.id ? arr.produto.push(item.nome) : ""
                );
            }

            if (
                item.carencia?.id &&
                !array.carencia.some((arr) => arr.id == item.carencia?.id)
            ) {
                array.carencia.push({
                    id: item.carencia.id,
                    item: item.carencia,
                    produto: [item.nome]
                });
            } else {
                array.carencia.some((arr) =>
                    arr.id == item.carencia?.id ? arr.produto.push(item.nome) : ""
                );
            }

            if (
                item.rede?.id &&
                !array.rede.some((arr) => arr.id == item.rede?.id)
            ) {
                array.rede.push({
                    id: item.rede.id,
                    item: item.rede,
                    produto: [item.nome]
                });
            } else {
                array.rede.some((arr) =>
                    arr.id == item.rede?.id ? arr.produto.push(item.nome) : ""
                );
            }

            if (
                item.reembolso?.id &&
                !array.reembolso.some((arr) => arr.id == item.reembolso?.id)
            ) {
                array.reembolso.push({
                    id: item.reembolso.id,
                    item: item.reembolso,
                    produto: [item.nome]
                });
            } else {
                array.reembolso.some((arr) =>
                    arr.id == item.reembolso?.id ? arr.produto.push(item.nome) : ""
                );
            }
            if (
                item.entidades?.id &&
                !array.entidades.some((arr) => arr.id == item.entidades?.id)
            ) {
                array.entidades.push({
                    id: item.entidades.id,
                    item: item.entidades,
                    produto: [item.nome]
                });
            } else {
                array.entidades.some((arr) =>
                    arr.id == item.entidades?.id ? arr.produto.push(item.nome) : ""
                );
            }
        });

        setInfoDependente(dependentes);
        setInfo(array);
        setModalVisualizarDetalhe(true);
        handleCarregamento(false);
    };
    const gerarTabelaSelecionada = (dados, status = false) => {
        let array = {
            nome: "",
            id: calculoDetalhe?.id,
            estado: calculoDetalhe?.estado,
            produto: [],
            faixas: [],
            observacoes: [],
            areacomercializacao: [],
            dependente: [],
            documento: [],
            pagamento: [],
            rede: [],
            reembolso: [],
            carencia: [],
            entidades: []
        };
        dados.map((item) => {
            if (
                selecteds.some(
                    (item3) =>
                        item3.tabela === item.tabela && item3.calculo === item.calculo
                )
            ) {
                array.produto.push({
                    id: item.info?.item?.id,
                    nome: item.info?.item?.nome,
                    cobertura: item.info?.item?.cobertura,
                    acomodacao: item.info?.item?.acomodacao,
                    coparticipacao: item.info?.item?.coparticipacao,
                    percentual: item.info?.item?.percentual,
                    vidas: `${item.info?.item?.minvidas} a ${item.info?.item?.maxvidas}`,
                    faixas: item.info?.item?.tabelafaixas?.sort(
                        (pessoa1, pessoa2) => pessoa1.id - pessoa2.id
                    ),
                    precovida: item.info?.item?.precovida,
                    imagem: item?.dados?.imagem,
                    operadora: item?.dados?.operadora,
                    status: item.status
                });

                if (
                    item.info.item.observaco?.id &&
                    !array.observacoes.some(
                        (arr) => arr.id == item.info.item.observaco.id
                    )
                ) {
                    array.observacoes.push({
                        id: item.info.item.observaco.id,
                        item: item.info.item.observaco,
                        produto: [item.info.item.nome],
                        operadora: item?.dados?.operadora
                    });
                } else {
                    array.observacoes.some((arr) =>
                        arr.id == item.info.item.observaco?.id
                            ? arr.produto.push(item.info.item.nome)
                            : ""
                    );
                }
                if (
                    item.info.item.areacomercializacao?.id &&
                    !array.areacomercializacao.some(
                        (arr) => arr.id == item.info.item.areacomercializacao?.id
                    )
                ) {
                    array.areacomercializacao.push({
                        id: item.info.item.areacomercializacao.id,
                        produto: [item.info.item.nome],
                        municipios: item.info.item.areacomercializacaoestadosmunicipios
                    });
                } else {
                    array.areacomercializacao.some((arr) =>
                        arr.id == item.info.item.areacomercializacao?.id
                            ? arr.produto.push(item.info.item.nome)
                            : ""
                    );
                }
                if (
                    item.info.item.dependente?.id &&
                    !array.dependente.some(
                        (arr) => arr.id == item.info.item.dependente?.id
                    )
                ) {
                    array.dependente.push({
                        id: item.info.item.dependente?.id,
                        item: item.info.item.dependente,
                        produto: [item.info?.item?.nome],
                        operadora: item?.dados?.operadora
                    });
                } else {
                    array.dependente.some((arr) =>
                        arr.id == item.info.item.dependente?.id
                            ? arr.produto.push(item.info.item.nome)
                            : ""
                    );
                }
                if (
                    item.info.item.documento?.id &&
                    !array.documento.some(
                        (arr) => arr.id == item.info.item.documento?.id
                    )
                ) {
                    array.documento.push({
                        id: item.info.item.documento.id,
                        item: item.info.item.documento,
                        produto: [item.info?.item?.nome],
                        operadora: item?.dados?.operadora
                    });
                } else {
                    array.documento.some((arr) =>
                        arr.id == item.info.item.documento?.id
                            ? arr.produto.push(item.info.item.nome)
                            : ""
                    );
                }
                if (
                    item.info.item.pagamento?.id &&
                    !array.pagamento.some(
                        (arr) => arr.id == item.info.item.pagamento?.id
                    )
                ) {
                    array.pagamento.push({
                        id: item.info.item.pagamento.id,
                        item: item.info.item.pagamento,
                        produto: [item.info?.item?.nome],
                        operadora: item?.dados?.operadora
                    });
                } else {
                    array.pagamento.some((arr) =>
                        arr.id == item.info.item.pagamento?.id
                            ? arr.produto.push(item.info.item.nome)
                            : ""
                    );
                }
                if (
                    item.info.item.carencia?.id &&
                    !array.carencia.some(
                        (arr) => arr.id == item.info.item.carencia?.id
                    )
                ) {
                    array.carencia.push({
                        id: item.info.item.carencia.id,
                        item: item.info.item.carencia,
                        produto: [item.info?.item?.nome],
                        operadora: item?.dados?.operadora
                    });
                } else {
                    array.carencia.some((arr) =>
                        arr.id == item.info.item.carencia?.id
                            ? arr.produto.push(item.info.item.nome)
                            : ""
                    );
                }
                if (
                    item.info.item.rede?.id &&
                    !array.rede.some((arr) => arr.id == item.info.item.rede?.id)
                ) {
                    array.rede.push({
                        id: item.info.item.rede.id,
                        item: item.info.item.rede,
                        produto: [item.info?.item?.nome],
                        operadora: item?.dados?.operadora
                    });
                } else {
                    array.rede.some((arr) =>
                        arr.id == item.info.item.rede?.id
                            ? arr.produto.push(item.info.item.nome)
                            : ""
                    );
                }
                if (
                    item.info.item.reembolso?.id &&
                    !array.reembolso.some(
                        (arr) => arr.id == item.info.item.reembolso?.id
                    )
                ) {
                    array.reembolso.push({
                        id: item.info.item.reembolso.id,
                        item: item.info.item.reembolso,
                        produto: [item.info?.item?.nome],
                        operadora: item?.dados?.operadora
                    });
                } else {
                    array.reembolso.some((arr) =>
                        arr.id == item.info.item.reembolso?.id
                            ? arr.produto.push(item.info.item.nome)
                            : ""
                    );
                }
                if (
                    item.entidades?.id &&
                    !array.entidades.some((arr) => arr.id == item.entidades?.id)
                ) {
                    array.entidades.push({
                        id: item.info.item.entidades.id,
                        item: item.info.item.entidades,
                        produto: [item.info?.item?.nome],
                        operadora: item?.dados?.operadora
                    });
                } else {
                    array.entidades.some((arr) =>
                        arr.id == item.info.item.entidades?.id
                            ? arr.produto.push(item.info.item.nome)
                            : ""
                    );
                }
            }
        });

        setInfoSelecionado(array);
        if (status) {
            setModalVisualizarDetalheSelecionado(true);
        }
    };
    const Listagem = async () => {
        setCarregamento(true)
        handleCarregamento(true);
        let dados = await Api.listagem();
        setList(dados?.list);
        Informacoes(dados?.list)
        handleCarregamento(false);
    };
    const Informacoes = async (list) => {
        let item = list.find((item) => Number(item?.id) == Number(id))
        handleLimpar(true);
        handleCarregamento(true);
        await handleCalculo(item?.id);
        setRamo(item?.ramo)
       
        handleCarregamento(false);
     
    }
const info2 =async()=>{
    if (idOperadora) {
         
        let dados = calculo.find((item)=>Number(item?.id) ===Number(idOperadora) )
      
        if(dados?.id){
            handleCarregamento(true);
            await handleOperadora(dados?.id, { ids: dados.tabelas?.map((item2) => { return item2.id }) });
        }
       
    }
    setCarregamento(false)
}
    useEffect(()=>{
        if(calculo.length>0){
            info2()
        
        }
     
    },[infoSelecionado,calculo])
    const handleExcluir = async () => {
        handleCarregamento(true);
        let list = await Api.delete({ id: id });
        setList(list);
        setModalExcluir(false);
        handleCarregamento(false);
    };
    const handleFiltro = async () => {
        handleCalculo(id);
        setModalFiltro(false);
    };
    const handleCalculo = async (id) => {
        handleCarregamento(true);
        let dados = await Api.calculo({
            id: id,
            filtro1,
            filtro2,
            filtro3,
            filtro4,
            filtro5,
            filtro6
        });
        setCalculo(dados.list.list);
        setModalVisualizarOperadora(true);
        let val = dados.list.list.map((item) => {
            return {
                operadora: item.nome,
                valores: item.tabelas.map((item2) => {
                    return item2.tabelafaixas.map((item3) => {
                        return item3.valor;
                    });
                })
            };
        });
        let val2 = val.map((item) => {
            return {
                operadora: item.operadora,
                valores: item.valores.flat().concat()
            };
        });
        let val3 = val2.map((item) => {

            return {
                operadora: item.operadora,
                valores: item.valores.map((valor) => {
                    return parseFloat(valor?.replace(",", "."));
                })
            };
        });

        let val4 = val3.map((item) => {
            return {
                operadora: item.operadora,
                valores: [Math.max(...item.valores), Math.min(...item.valores)]
            };
        });
        let valoresMaximos = [];
        let valoresMinimos = [];
        let valores = [];
        let operadoraValorMaximo;
        let operadoraValorMinimo;
        let operadoraValoIntermediario;
        let meio = 0;
        let valorDoMeio = 0;
        let valorMaximo = 0;
        let valorMinimo = 0;

        if (
            val3.length > 0 &&
            val4.length > 0 &&
            dados.list.cotacao.info.ramo != "1"
        ) {
            for (let i = 0; i < val3.length; i++) {
                valores.push(...val3[i].valores);
                operadoraValoIntermediario = val3[i].operadora;
            }
            for (let i = 0; i < val4.length; i++) {
                valoresMaximos.push(Math.max(...val4[i].valores));
                valoresMinimos.push(Math.min(...val4[i].valores));
            }

            valores.sort((a, b) => a - b); // ordena o array em ordem crescente
            meio = Math.floor(valores.length / 2);
            valorDoMeio = valores[meio];
            valorMaximo = valores[valores.length - 1];
            valorMinimo = valores[0];
            for (let i = 0; i < val4.length; i++) {
                if (Math.max(...val4[i].valores) === valorMaximo) {
                    operadoraValorMaximo = val4[i].operadora;
                }
                if (Math.min(...val4[i].valores) === valorMinimo) {
                    operadoraValorMinimo = val4[i].operadora;
                }
            }
        } else {
            let val = dados.list.list.map((item) => {
                return {
                    operadora: item.nome,
                    valores: item.tabelas.map((item2) => {
                        return item2.precovida;
                    })
                };
            });
            let val2 = val.map((item) => {
                return {
                    operadora: item.operadora,
                    valores: item.valores.flat().concat()
                };
            });
            let val3 = val2.map((item) => {
                return {
                    operadora: item.operadora,
                    valores: item.valores.map((valor) => {
                        return parseFloat(valor.replace(",", "."));
                    })
                };
            });
            let val4 = val3.map((item) => {
                return {
                    operadora: item.operadora,
                    valores: [Math.max(...item.valores), Math.min(...item.valores)]
                };
            });
            for (let i = 0; i < val3.length; i++) {
                valores.push(...val3[i].valores);
                operadoraValoIntermediario = val3[i].operadora;
            }
            for (let i = 0; i < val4.length; i++) {
                valoresMaximos.push(Math.max(...val4[i].valores));
                valoresMinimos.push(Math.min(...val4[i].valores));
            }
            valores.sort((a, b) => a - b); // ordena o array em ordem crescente
            meio = Math.floor(valores.length / 2);
            valorDoMeio = valores[meio];
            valorMaximo = Math.max(...valoresMaximos);
            valorMinimo = Math.min(...valoresMinimos);
            for (let i = 0; i < val4.length; i++) {
                if (Math.max(...val4[i].valores) === valorMaximo) {
                    operadoraValorMaximo = val4[i].operadora;
                }
                if (Math.min(...val4[i].valores) === valorMinimo) {
                    operadoraValorMinimo = val4[i].operadora;
                }
            }
        }

        valores = valores.filter((item) => item)
        valorDoMeio = valores[meio];
        valorMaximo = valores[valores.length - 1];
        valorMinimo = valores[0];
        setCalculoDetalhe({
            operadoraValorMaximo: operadoraValorMaximo,
            operadoraValorMinimo: operadoraValorMinimo,
            operadoraValoIntermediario: operadoraValoIntermediario,
            valorMaximo: valorMaximo,
            valorMinimo: valorMinimo,
            valorDoMeio: valorDoMeio,
            id: dados.list.cotacao.id,
            vidas: dados.list.cotacao.vidas,
            nome: dados.list.cotacao.nome,
            nomeempresa: dados.list.cotacao.empresa,
            estado: dados.list?.cotacao?.info?.estado?.nome
        });
     
    };

    const filtrar = async (nome) => {
        handleCarregamento(true);
        setLoading(true);
        let list = await Api.filtro({ nome: nome });
        setList(list.list);
        setLoading(false);
        handleCarregamento(false);
    };
    const handleDados = async (id) => {
        handleCarregamento(true);
        let dados = await Api.dados({ id: id });
        setDados({
            tipo: dados.list.tipo,
            ramo: dados.list.ramo,
            descricao: dados.list.descricao,
            pessoacontato: dados.list.pessoacontato,
            email: dados.list.email,
            telefone: dados.list.telefone,
            estado: dados.list.idestado,
            profissao: dados.list.idprofissao,
            titular: dados.list.titular,
            nomeempresa: dados.list.nomeempresa,
            tipocnpj: dados.list.tipocnpj,
            dependentes: dados.list.dependentes,
            areacomercializacao: dados.list.idareacomercializacao
        });

        let newFaixa = faixas;
        dados.list.cotacoesfaixas.forEach((elemento2) => {
            const elemento1 = newFaixa.find(
                (elemento1) => elemento1.id === elemento2.idfaixa
            );
            if (elemento1) {
                elemento1.value = elemento2.valor;
            }
        });

        setFaixas(newFaixa);
        let val1 = dados.list.cotacoesdependentes.map((item) => {
            return item.dependente;
        });
        setItens(val1);
        handleCarregamento(false);

    };
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: "" || id,
            tipo: dados?.tipo ? dados.tipo : "1",
            ramo: dados?.ramo ? dados.ramo : ramo ? ramo : "",
            descricao: dados?.descricao ? dados.descricao : "",
            pessoacontato: dados?.pessoacontato ? dados.pessoacontato : "",
            email: dados?.email ? dados.email : "",
            telefone: dados?.telefone ? dados.telefone : "",
            estados: dados?.estado ? dados.estado : "",
            profissao: dados?.profissao ? dados.profissao : "",
            titular: dados?.titular ? dados.titular : "",
            nomeempresa: dados?.nomeempresa ? dados.nomeempresa : "",
            tipocnpj: dados?.tipocnpj ? dados.tipocnpj : "",
            dependentes: dados?.dependentes ? dados.dependentes : "",
            areacomercializacao: dados?.areacomercializacao
                ? dados.areacomercializacao
                : "",
            faixas: null,
            dependentesarray: null
        },

        onSubmit: async (values) => {
            handleCarregamento(true);
            setLoading(true);
            values.faixas = faixas;
            values.dependentesarray = itens;
            if (id) {
                let json = await Api.editar(values);

                if (json.error) {
                    setError(true);
                    setResposta(true);
                    setMsg(json.msg);
                } else {
                    if (salvarVisualizar) {
                        handleCalculo(json.id);
                        setSalvarVisualizar(false);
                        setRamo(ramo)
                    }
                    Listagem();
                    setBuscar("");
                    handleLimpar(true);
                    setModalEditar(false);
                }
                setLoading(false);
            } else {
                let json = await Api.cadastro(values);

                if (json.error) {
                    setError(true);
                    setResposta(true);
                    setMsg(json.msg);
                } else {
                    if (salvarVisualizar) {
                        handleCalculo(json.id);
                        setSalvarVisualizar(false);
                    }

                    setError(false);
                    setResposta(true);
                    setMsg(json.msg);
                    handleLimpar(false);
                    Listagem();
                }
                setLoading(false);
            }
            document.documentElement.scrollTo({ top: 0, behavior: "smooth" });
            handleCarregamento(false);
        }
    });

    const handleLimpar = (status = false) => {
        if (status) {
            setResposta(false);
        }
        validation.values.descricao = "";
        validation.values.pessoacontato = "";
        validation.values.email = "";
        validation.values.telefone = "";
        validation.values.estados = "";
        validation.values.profissao = "";
        validation.values.titular = "";
        validation.values.nomeempresa = "";
        validation.values.tipocnpj = "";
        validation.values.dependentes = "";
        validation.values.areacomercializacao = "";
        validation.values.faixas = null;
        validation.values.dependentesarray = null;
        setItens([{}]);
        setInfoDependente([]);
        setInfoSelecionado({});
        setSelecteds([]);
        setDados({
            tipo: "1",
            ramo: "",
            descricao: "",
            pessoacontato: "",
            email: "",
            telefone: "",
            estado: "",
            profissao: "",
            titular: "",
            nomeempresa: "",
            tipocnpj: "",
            dependentes: "",
            areacomercializacao: ""
        });
        let newFaixa = faixas;
        newFaixa.forEach((elemento2) => {
            elemento2.value = "";
        });
        setFaixas(newFaixa);
    };
    const [selecteds, setSelecteds] = useState([]);
    const handleMouseOver = (idtabela, id) => {
        let elemento = document.querySelector(`tr[id="${id}"]`);
        if (elemento) {

            elemento.style.backgroundColor = "#fff";
            elemento.style.border = "1px solid #0F4CBA";
            elemento.style.color = "#092140";


        }
    };
    const handleMouseOut = (idtabela, id) => {
        let elemento = document.querySelector(`tr[id="${id}"]`);
        if (
            elemento &&
            !selecteds.some(
                (item) => item.tabela === idtabela && item.calculo === id
            )
        ) {
            elemento.style.backgroundColor = "#fff";
            elemento.style.borderColor = "#eff1f2";
            elemento.style.color = "#092140";
        }
    };
    const handleClick = (idtabela, id, info, dados) => {
        if (
            selecteds.some(
                (item) => item.tabela == idtabela && item.calculo == id
            )
        ) {
            let item = selecteds;
            let filtro = item.filter(
                (item1) => item1.tabela !== idtabela && item1.calculo !== id
            );
            setSelecteds(filtro);
        } else {
            let item = selecteds;
            item.push({ tabela: idtabela, calculo: id, info, dados });
            setSelecteds(item);
        }

    };
    const handleVisualizarCotacoes = () => {
        gerarTabelaSelecionada(selecteds, true);
    };
    const [area, setArea] = useState(1);
    useEffect(() => {
        var url = window.location.href;
        url = url.split("/");
        if (url.includes("minhascotacoes")) {
            setArea(2);
        }
        if (obj) {
            if (!obj?.id) {
                window.location.href = '/'
            }
        }
    }, []);
    const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

    useEffect(() => {
      const handleResize = () => {
        setIsDesktop(window.innerWidth > 768);
      };
  
      window.addEventListener("resize", handleResize);
  
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }, [info]);
    return (
        <section className="about-two rpb-100">
            <Layouts style={{ fontFamily: "Open Sans, sans-serif", textTransform: 'none' }} pageTitle="Cotações"   breadcrumbs={[
      { name: "Home", path: "../" },
      { name: "Cotações", path: "/tabelacotacao" },
    ]} >
                {!carregamento ?
                    <Visualizar handleCarregamento={handleCarregamento}
                        setModalFiltro={setModalFiltro}
                        handleOperadora={handleOperadora}
                        calculoDetalhe={calculoDetalhe}
                        calculo={calculo}
                        modalVisualizarOperadora={modalVisualizarOperadora}
                        setModalVisualizarOperadora={setModalVisualizarOperadora}
                        handleVisualizarCotacoes={handleVisualizarCotacoes}
                        ramo={ramo}
                    />
                    : 
                    <div  className={`col-lg-12 col-sm-12 item `} style={{ marginTop: "10px", minHeight: 299,display:'flex',justifyContent:'center',alignItems:'center' }}
                    >
                       <img src={Icone} className="rotating-image" />
                    </div>
                }

                <VisualizarDetalhe
                    handleCarregamento={handleCarregamento}
                    handleClick={handleClick}
                    handleMouseOut={handleMouseOut}
                    handleMouseOver={handleMouseOver}
                    selecteds={selecteds}
                    setModalVisualizarDetalhe={setModalVisualizarDetalhe}
                    infoDependente={infoDependente}
                    faixas={faixas}
                    info={info}
                    modalVisualizarDetalhe={modalVisualizarDetalhe}
                    calculoDetalhe={calculoDetalhe}
                    handleVisualizarCotacoes={handleVisualizarCotacoes}
                />
                <VisualizarSelecionado
                    handleCarregamento={handleCarregamento}
                    selecteds={selecteds}
                    setModalVisualizarDetalhe={
                        setModalVisualizarDetalheSelecionado
                    }
                    infoDependente={infoDependente}
                    faixas={faixas}
                    info={infoSelecionado}
                    modalVisualizarDetalhe={modalVisualizarDetalheSelecionado}
                    calculoDetalhe={calculoDetalhe}
                    handleClick={handleClick}
                    gerarTabelaSelecionada={gerarTabelaSelecionada}
                />

                <Filtros filtro1={filtro1} filtro2={filtro2} filtro3={filtro3} filtro4={filtro4} filtro5={filtro5} filtro6={filtro6} municipios={municipios} handleCarregamento={handleCarregamento} modalFiltro={modalFiltro} setModalFiltro={setModalFiltro} setFiltro1={setFiltro1} setFiltro2={setFiltro2} setFiltro3={setFiltro3} setFiltro4={setFiltro4} setFiltro5={setFiltro5} setFiltro6={setFiltro6} handleFiltro={handleFiltro} ramo={ramo} />

            </Layouts>

        </section>
    );
}

export default PageCotacoes;

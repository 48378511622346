import axios from "axios";
import { baseUrlApi } from './baseUrl'

const Token = localStorage.getItem("authToken");
const axiosInstance = axios.create({
  baseURL: `${baseUrlApi}/cotacoes`,
  headers:{
    'Authorization':`Bearer ${Token}`,
    'ContentType':'application/json'
    }
});
export const Api = {
  estados: async (dados) => {
    let response = await axiosInstance.post("/estados",dados);
    return response.data.option;
  },
  faixa: async () => {
    let response = await axiosInstance.post("/faixa");
    return response.data.input;
  },
  faixas: async (dados) => {
    let response = await axiosInstance.post("/faixas", dados);
    return response.data.input;
  },
  listagem: async () => {
    let response = await axiosInstance.post("/listagem");
    return response.data;
  },
  filtro: async (dados) => {
    let response = await axiosInstance.post("/buscar", dados);
    return response.data;
  },
  status: async (dados) => {
    let response = await axiosInstance.post("/status", dados);
    return response.data;
  },
  profissoes: async () => {
    let response = await axiosInstance.post("/profissoes");
    return response.data;
  },
  areacomercializacao: async () => {
    let response = await axiosInstance.post("/areacomercializacao");
    return response.data;
  },
  cadastro: async (dados) => {
    let response = await axiosInstance.post("/cadastro", dados);
    return response.data;
  },
  delete: async (dados) => {
    let response = await axiosInstance.delete("/delete/" + dados.id);
    return response.data.list;
  },
  calculo: async (dados) => {
    let response = await axiosInstance.post("/calculo/", dados);
    return response.data;
  },
  dados: async (dados) => {
    let response = await axiosInstance.post("/dados/", dados);
    return response.data;
  },
  calculoDetalhe: async (dados) => {
    let response = await axiosInstance.post("/calculodetalhe/", dados);
    return response.data;
  },
  editar: async (dados) => {
    let response = await axiosInstance.post("/editar", dados);
    return response.data;
  },
  municipio: async () => {
    let response = await axiosInstance.post("/municipios");
    return response.data;
  },
  agendar: async (dados) => {
    let response = await axiosInstance.post("/agendar", dados);
    return response.data;
  },
  agendamentos: async (dados) => {
    let response = await axiosInstance.post("/agendamentoUnit", dados);
    return response.data;
  },
  excluirAgendamentos: async (dados) => {
    let response = await axiosInstance.post("/excluiragendamento", dados);
    return response.data;
  },
  agendamento: async () => {
    let response = await axiosInstance.post("/agendamento");
    return response.data;
  },
  tiposOperadoras: async (dados) => {
    let response = await axiosInstance.post("/tiposOperadoras", dados);
    return response.data.option;
  },
  compartilhar: async (dados) => {
    let response = await axiosInstance.post("/compartilhar", dados);
    return response.data;
  }
};

import React from "react";
import { Modal, Row, Col,Alert } from "reactstrap";
import { ExitModal } from "../../../components/exitModal";
export const ValidacaoEmail = ({ modalEmail, setModalEmail, email, handleInputChangeEmail, codigo5, codigo6, codigo7, codigo8, setCodigo5, setCodigo6, setCodigo7, setCodigo8, handleDisparoEmail, loading, start, time, handleValidarEmail, input5Ref, input6Ref, input7Ref, input8Ref }) => {
  return (
    <Modal isOpen={modalEmail} centered={true}>
      <div className="modal-header" style={{ border: 'none' }}>
        <ExitModal setModal={setModalEmail} />

      </div>
      <div className="modal-body" style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 20, fontWeight: 300 }}>

        <p style={{ color: '#092140', textAlign: 'center' }}>Valide seu Email</p>

        <Alert color="success" style={{ marginTop: "13px", textAlign: 'center', fontWeight: 300 }}>
        Digite o código enviado por E-mail <br />
        {email}
        </Alert>

     


        <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
          <Col  lg={3} style={{ flex: 1 }}>
            <input className="form-control" value={codigo5} ref={input5Ref} onChange={(e) => {
              handleInputChangeEmail(e);
              setCodigo5(e.target.value);
            }} style={{ textAlign: "center" }} maxLength={1} />
          </Col>
          <Col  lg={3} style={{ flex: 1 }}>
            <input className="form-control" value={codigo6} ref={input6Ref} onChange={(e) => {
              handleInputChangeEmail(e);
              setCodigo6(e.target.value);
            }} style={{ textAlign: "center" }} maxLength={1} />
          </Col>
          <Col  lg={3} style={{ flex: 1 }}>
            <input className="form-control" value={codigo7} ref={input7Ref} onChange={(e) => {
              handleInputChangeEmail(e);
              setCodigo7(e.target.value);
            }} style={{ textAlign: "center" }} maxLength={1}
            />
          </Col>
          <Col  lg={3} style={{ flex: 1 }}>
            <input className="form-control" value={codigo8} ref={input8Ref}
              onChange={(e) => {
                handleInputChangeEmail(e);
                setCodigo8(e.target.value);
              }} style={{ textAlign: "center" }} maxLength={1}
            />
          </Col>
        </Row>
      </div>

      <Row>

        <Col lg={12} style={{ marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} >
          <button className="btn btn-default mb-2" disabled={loading ? loading : start ? true : false} onClick={() => { handleDisparoEmail() }}  >{loading ? 'Reenviar codigo' : start ? `00:${time < 10 ? '0' + time : time}` : `Reenviar código`}</button>

          <button className="btn btn-primary " style={{ marginBottom: 20, width: 160 }} disabled={loading} onClick={() => { handleValidarEmail(); }}  >
            Avançar
          </button>
        </Col>
      </Row>

      {/* <div className="modal-footer" style={{ border: 'none', paddingRight: 25, marginTop: -9 }}>
        <button className="btn btn-link" style={{ color: 'red' }} disabled={loading ? loading : start ? true : false} onClick={() => {
          handleDisparoEmail();
        }}
        >
          {loading ? "Reenviar código" : start ? `00:${time < 10 ? "0" + time : time}` : `Reenviar código`}
        </button>
        <button className="btn btn-primary" disabled={loading} onClick={() => { handleValidarEmail(); }}  >
          Avançar
        </button>
      </div> */}
    </Modal>
  )
}
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { Api } from "../../../../api";
import boneco from "../../../../assets/images/icones/boneco.png";
import bonecoEditar from "../../../../assets/images/icones/editar.png";
import card from "../../../../assets/images/icones/card.png";
import dente from "../../../../assets/images/icones/dente.png";
import estetoscopio from "../../../../assets/images/icones/estetoscopio.png";
import sino from "../../../../assets/images/icones/sino.png";
import empresasParceias from "../../../../assets/images/icones/empresas_parceiras.png";
import { FaAngleUp, FaWhatsapp } from "react-icons/fa";
import ferramentas_vendas from "../../../../assets/images/icones/ferramentas_vendas.png";
import material_divulgacao from "../../../../assets/images/icones/material_divulgacoes.png";
import auto_moto from "../../../../assets/images/icones/auto_moto.png";
import faca_cotacoes from "../../../../assets/images/icones/faca_cotacoes.png";
import home from "../../../../assets/images/icones/home.png";
import material_vendas from "../../../../assets/images/icones/material_vendas.png";
import pet from "../../../../assets/images/icones/pet.png";
import produtosEmpresas from "../../../../assets/images/icones/produtos_empresas.png";
import seja_parceiro from "../../../../assets/images/icones/seja_parceiro.png";
import treinamentos from "../../../../assets/images/icones/treinamentos.png";
import vendas_online from "../../../../assets/images/icones/vendas_online.png";
import uti_movel from "../../../../assets/images/icones/uti_movel.png";
import buscar from "../../../../assets/images/icones/busca.png";
import condominio from "../../../../assets/images/icones/condominio.png";
import residencial from "../../../../assets/images/icones/residencial.png";
import produtos_ramos_atuacao from "../../../../assets/images/icones/produtos_ramos_atuacao.png";
import seguro from "../../../../assets/images/icones/seguro.png";
import viagem from "../../../../assets/images/icones/viagem.png";
import wpp from "../../../../assets/images/icones/wpp.png";
import { Login } from "../Login";
import { MeusDados } from "../MeusDados";
import { googleLogout } from '@react-oauth/google';
import { GoGear } from "react-icons/go";
import { RxExit } from "react-icons/rx";
import React from "react";
import "./style.css";
import { Parceiro } from "../Parceiro";
import { baseUrlApp } from "../../../../api/baseUrl";
import { Modal } from "reactstrap";
import { ItensMenu } from "../../../components/itemMenu";
import { ExitModal } from "../../../components/exitModal";
import { useCookies } from "react-cookie";


function Drawer({ drawer, action }) {
  const [itemSize, setSize] = useState("");
  const [item, setItem] = useState("");
  const [modalDados, setModalDados] = useState();
  const handler = (e, value) => {
    // e.preventDefault();
    let getItems = document.querySelectorAll(`#${value} li`).length;
    if (getItems > 0) {
      getItems = getItems / 2
      if (item !== value) {
        setSize(`${getItems * 40}px`);
        setItem(value);
      } else {
        setItem("");
      }
    }
  };


  const [cookie,setCookie] = useCookies(['authUser'])
  const obj =cookie.authUser

  const navigate = useNavigate();
  const [modalApagar, setModalApagar] = useState();
  const [estadosSaude, setEstadosSaude] = useState();
  const [estadosOdonto, setEstadosOdonto] = useState();
  const [loadingSaude, setLoadingSaude] = useState(false);
  const [loadingOdonto, setLoadingOdonto] = useState(false);
  const [escolha, setEscolha] = useState(0);
  const [escolhasub, setEscolhaSub] = useState(0);
  const [modalLogin, setModalLogin] = useState(false);
  const [modalParceiro, setModalParceiro] = useState(false);
  const handleEstadosSaude = async () => {
    setLoadingSaude(true);
    let list = await Api.estadosSaude();
    setEstadosSaude(list.list);
    setLoadingSaude(false);
  };
  const handleEstadosOdonto = async () => {
    setLoadingOdonto(true);
    let list = await Api.estadosOdontologico();
    setEstadosOdonto(list.list);
    setLoadingOdonto(false);
  };
  useEffect(() => {
    handleEstadosSaude();
    handleEstadosOdonto();
  }, []);
  const handleLink = () => {
    window.open(`${baseUrlApp}/login/${obj?.id}/${obj?.token}`, "_blank");
  }
  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1).toLowerCase();
  };
  const [comunicados, setComunicados] = useState('')
  const [treinamento, setTreinamento] = useState('')
  const [materiais, setMaterial] = useState('')
  useEffect(() => {
    handleDados()
    handleRamos()
  }, [])
  
  const handleDados = async () => {
    let response = await Api.blogs();
    let list = response?.list?.list
    let id = obj?.id
    if (id) {
      id = JSON.parse(id)
    }
    let listagem = list.filter((item) => (!item?.blogviews?.some((item2) => (item2?.iduser == id))))
    setComunicados(listagem?.length)
    let json = await Api.treinamentos();
    let lists = json?.list
    setTreinamento(lists?.length)

    
    let responseJson = await Api.materiais();
    let listas = responseJson?.list.list
    listas = listas.filter((item) => (!item?.materialviews?.some((item2) => (item2?.iduser == id))))
    setMaterial(listas?.length)
  };
  const [modal, setModal] = useState(false)
  const handleLogin = (e, privado, link) => {
    //  e.preventDefault()
    if (privado ? !obj?.id : false) {
      setCookie('authPagehist','/ferramentas', { path: "/", maxAge: 365 * 24 * 60 * 60, sameSite: "strict", secure: true });

      // localStorage.setItem("authPagehist", '/ferramentas');
      setModalLogin(true)
    } else {
      navigate(link)
    }
  }
  const [ramos, setRamos] = useState([])
  const handleRamos = async () => {
    let value = await Api.ramosVendaOline()

    let result = value.list.filter((item) => item.tiposoperadoras?.some((item) => item.operadora.linkvenda) ? true : item.operadoraslinks?.some((item) => item.link))
    result = result.map((item) => { return { id: item.id, nome: item.nome, icone: item.icone, tipo: item.tiposoperadoras?.some((item) => item.operadora.linkvenda) ? 'Odonto-Saude' : item.operadoraslinks.some((item) => item.link) ? 'Outros' : '' } })
 
    let uniqueArray = result.reduce((acc, current) => {
      const exists = acc.find(item => item.id === current.id);
      if (!exists) {
        acc.push(current);
      }
      return acc;
    }, []);
    uniqueArray.sort((a, b) => a.ordem - b.ordem)
    setRamos(uniqueArray)
  }

  return (
    <>
      <div
        onClick={(e) => action(e)}
        className={`off_canvars_overlay ${!modalLogin && drawer ? "active" : ""
          }`}
        style={{ width: '100%' }}
      ></div>

      <div className="offcanvas_menu" style={{ display: 'block' }}>
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div
                className={`offcanvas_menu_wrapper ${!modalLogin && drawer ? "active" : ""
                  }`}
                style={{ width: !modalLogin && drawer ? "290px" : "" }}
              >
                <ExitModal setModal={(e) => action(e)} />


                <div id="menu" style={{marginTop:20}} >
                  <ul className="offcanvas_main_menu">
                    {!obj?.nome && (
                      <ItensMenu itemMenu={item} setItem={setItem} setSize={setSize} action={action} obj={obj}  setModalLogin={setModalLogin}
                        title="Entre ou Cadastre-se" link={'/'} imagem={boneco}
                        privado={true} />
                    )}


                    {obj?.id && (
                      <ItensMenu
                        itemMenu={item} setItem={setItem} setSize={setSize} itemSize={itemSize} obj={obj} action={action} setModalLogin={setModalLogin}
                        title={`Olá, ${capitalizeFirstLetter(obj?.nomefantasia ? obj?.nomefantasia?.split(' ')[0] : obj?.nome?.split(' ')[0])}`} link={''} imagem={boneco}
                        privado={true} html={""} id={'home'}
                        subMenu={[
                          { link: '', title: 'Meu Perfil', imagem: bonecoEditar, privado: false, onClick: setModalDados },
                          { link: '', title: 'Gerenciar', icone: <GoGear size={15} style={{ marginRight: 4 }} color="#5c5c5c" />, onClick: handleLink },
                          {
                            link: '', title: 'Sair', icone: <RxExit size={15} style={{ marginRight: 8 }} color="#5c5c5c" />, onClick: () => {
                              setCookie('authUser',"{}", { path: "/", maxAge: 365 * 24 * 60 * 60, sameSite: "strict", secure: true });

                              // localStorage.setItem("authUser", "{}");
                              window.location.href = '/'
                              googleLogout();
                            }
                          }
                        ]}
                      />
                    )}
                    <ItensMenu itemMenu={item} setItem={setItem} setSize={setSize} itemSize={itemSize} obj={obj} action={action} setModalLogin={setModalLogin}
                      title="Inicio" link={'/'} imagem={home}
                      privado={false} />

                    <ItensMenu itemMenu={item} setItem={setItem} setSize={setSize} itemSize={itemSize} obj={obj} action={action} setModalLogin={setModalLogin}
                      title="Ferramenta de Vendas" link={'/ferramentas'} imagem={ferramentas_vendas}
                      privado={true} />

                    <ItensMenu itemMenu={item} setItem={setItem} setSize={setSize} itemSize={itemSize} obj={obj} action={action} setModalLogin={setModalLogin}
                      title="Avisos e Comunicados" link={'/comunicados'} imagem={sino}
                      privado={false}
                      html={comunicados ?
                        <div style={{ position: 'absolute', left: 8, top: '-2px', borderRadius: '50%', color: 'red', width: 12, height: 12, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 11, fontWeight: 900, backgroundColor: 'red', color: '#fff' }} >{comunicados}</div>
                        : ""} />

                    <ItensMenu
                      itemMenu={item} setItem={setItem} setSize={setSize} itemSize={itemSize} obj={obj} action={action} setModalLogin={setModalLogin}
                      title="Agenda de Treinamentos" link={'/treinamentos'} imagem={treinamentos}
                      privado={true}
                      html={treinamento ?
                        <div style={{ position: 'absolute', left: 8, top: '-2px', borderRadius: '50%', color: 'red', width: 12, height: 12, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 11, fontWeight: 900, backgroundColor: 'red', color: '#fff' }} >{treinamento}</div>
                        : ""} />

                    <ItensMenu
                      itemMenu={item} setItem={setItem} setSize={setSize} action={action} obj={obj}  setModalLogin={setModalLogin}
                      title="Materiais de Divulgação" link={'/materiais'} imagem={material_divulgacao}
                      privado={true}  html={materiais ?
                        <div style={{ position: 'absolute', left: 8, top: '-2px', borderRadius: '50%', color: 'red', width: 12, height: 12, display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: 11, fontWeight: 900, backgroundColor: 'red', color: '#fff' }} >{materiais}</div>
                        : ""}/>

                    <ItensMenu
                      itemMenu={item} setItem={setItem} setSize={setSize} itemSize={itemSize} obj={obj} action={action} setModalLogin={setModalLogin}
                      title="Tabelas e Materiais de Vendas" link={''} imagem={material_vendas}
                      privado={true} html={""} id={'tabelaMateriaisVendas'}
                      subMenu={[
                        { link: '/plano/saude', title: 'Planos de Saúde', imagem: estetoscopio },
                        { link: '/plano/odontologico', title: 'Planos Odontológicos', imagem: dente },
                        { link: '/plano/pet', title: 'Planos Pet (cães e gatos)', imagem: pet },
                        { link: '/plano/uti', title: 'UTI Móvel Empergências 24h', imagem: uti_movel },
                        { link: '/plano/card', title: 'Cartões de Benefícios', imagem: card }
                      ]}
                    />

                    <ItensMenu
                      itemMenu={item} setItem={setItem} setSize={setSize} itemSize={itemSize} obj={obj} action={action} setModalLogin={setModalLogin}
                      title="Faça Suas Cotações" link={''} imagem={faca_cotacoes}
                      privado={true} html={""} id={'cotacoes'}
                      subMenu={[
                        { link: '/cotacoes', title: 'Planos de Saúde', imagem: estetoscopio },
                        { link: '/cotacoes', title: 'Planos Odontológicos', imagem: dente },
                        { link: '/tabela', title: 'Tabelas Personalizadas', imagem: card },
                        { link: '', title: 'Seguro Auto e Moto', imagem: auto_moto ,onClick:()=>{setModal(true)}},
                        { link: '', title: 'Seguro Residencial', imagem: residencial,onClick:()=>{setModal(true)} },
                        { link: '', title: 'Seguro Condomínio', imagem: condominio,onClick:()=>{setModal(true)} },
                        { link: '', title: 'Consórcios (Veículos e imóveis)', imagem: seguro ,onClick:()=>{setModal(true)} },
                      ]}
                    />

                    <ItensMenu
                      itemMenu={item} setItem={setItem} setSize={setSize} itemSize={itemSize} obj={obj} action={action} setModalLogin={setModalLogin}
                      title="Feche sua Vendas Online" link={''} imagem={vendas_online}
                      privado={true} html={""} id={'vendas'}
                      subMenu={ramos.map((item) => { return { link: `/vendaOnline/${item?.id}`, title: item.nome, imagem: `https://server.corpar.com.br/imagens/${item?.icone}` } })}
                    />

                    <ItensMenu
                      itemMenu={item} setItem={setItem} setSize={setSize} itemSize={itemSize} obj={obj} action={action} setModalLogin={setModalLogin}
                      title="Produtos e Empresas" link={''} imagem={produtosEmpresas}
                      privado={true} html={""} id={'produtos'}
                      subMenu={[
                        { link: '/produtos', title: 'Pesquisar Produtos', imagem: buscar, privado: true },
                        { link: '', title: 'Catálogo de Produtos', imagem: treinamentos,onClick:()=>{setModal(true)}},
                        { link: '/ramos', title: 'Ramos de Atuação', imagem: produtos_ramos_atuacao, privado: true },
                        { link: '/empresas', title: 'Empresas Parceiras', imagem: empresasParceias, privado: true }
                      ]}
                    />

                    <ItensMenu
                      itemMenu={item} setItem={setItem} setSize={setSize} itemSize={itemSize} obj={obj} action={action} setModalLogin={setModalLogin}
                      title="Seja Nosso(a) Parceiro(a)" link={''} imagem={seja_parceiro}
                      privado={true} html={""} id={''} onClick={() => { window.open("https://api.whatsapp.com/send/?phone=55800060706&text=&type=phone_number&app_absent=0", "_blank"); }}
                    />

                    <ItensMenu
                      itemMenu={item} setItem={setItem} setSize={setSize} itemSize={itemSize} obj={obj} action={action} setModalLogin={setModalLogin}
                      title="Fale Conosco" link={''} imagem={wpp}
                      privado={true} html={""} id={''} onClick={() => { window.open("https://api.whatsapp.com/send/?phone=55800060706&text=&type=phone_number&app_absent=0", "_blank"); }}
                    />

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Login modalLogin={modalLogin} setModalLogin={setModalLogin} />
      <Parceiro modalDados={modalParceiro} setModalDados={setModalParceiro} />
      <MeusDados modalDados={modalDados} setModalDados={setModalDados} />

      <Modal isOpen={modal} size='md' centered={true}>
        <div className="modal-header" style={{ border: '0px' }}>
          <ExitModal setModal={setModal} />

        </div>
        <div className="modal-body" style={{ border: '0px' }}>
          <div style={{ display: 'flex', justifyContent: 'center', fontSize: '20px' }}>Em breve.</div>
        </div>
        <div className="modal-footer" style={{ border: '0px' }}>

        </div>
      </Modal>
    </>
  );
}

export default Drawer;

import axios from "axios";
import { baseUrlApi } from './baseUrl'

const axiosInstance = axios.create({
  baseURL: baseUrlApi
});

export const Api = {
  login: async (dados) => {

    let response = await axiosInstance.post("/login", dados);
    if (response.data.error == false) {
      // cookies.set("authUser", JSON.stringify(response.data), { path: "/",  secure: true, sameSite: "Strict"});
      //localStorage.setItem("authUser", JSON.stringify(response.data));
    }
    return response.data;
  },
  cadastro: async (dados) => {
    let response = await axiosInstance.post("/login/cadastro", dados);
    return response.data;
  },
  validarCelular: async (dados) => {
    let response = await axiosInstance.post("/login/valida/celular", dados);
    return response.data;
  },
  validarEmail: async (dados) => {
    let response = await axiosInstance.post("/login/valida/email", dados);
    return response.data;
  },
  validacao: async (dados) => {
    let response = await axiosInstance.post("/login/validacao", dados);
    if (response.data.error == false) {
      cookies.set("authUser", JSON.stringify(response.data), { path: "/",  secure: true, sameSite: "Strict"});
      // localStorage.setItem("authUser", JSON.stringify(response.data));
    }
    return response.data;
  },
  validarEnderenco: async (dados) => {
    let response = await axiosInstance.post("/login/valida/endereco", dados);
    return response.data;
  },
  disparoEmail: async (dados) => {
    let response = await axiosInstance.post("/login/disparar/email", dados);
    return response.data;
  },
  disparoCelular: async (dados) => {
    let response = await axiosInstance.post("/login/disparar/celular", dados);
    return response.data;
  },
  disparoValidacao: async (dados) => {
    let response = await axiosInstance.post("/login/disparar/token", dados);
    return response.data;
  },
  dispararCelularCodigo: async (dados) => {
    let response = await axiosInstance.post("/login/dispararCelularCodigo", dados);
    return response.data;
  },

  Validar: async (dados) => {
    let response = await axiosInstance.post("/login/disparar/senha", dados);
    return response.data;
  },
  validarSenha: async (dados) => {
    let response = await axiosInstance.post("/login/valida/senha", dados);
    return response.data;
  },
  editar: async (dados) => {
    let response = await axiosInstance.post("/usuarios/editPerfil", dados);
    return response.data;
  }
};

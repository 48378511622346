import axios from "axios";
import { baseUrlApi } from './baseUrl'
const token = localStorage.getItem("authToken");

const axiosInstance = axios.create({
  baseURL: `${baseUrlApi}/parceiros`,
  headers:{
    'Authorization':`Bearer ${token}`,
    'ContentType':'application/json'
    }
});
export const Api = {
  cadastro: async (dados) => {
    let response = await axiosInstance.post("/cadastro", dados);
    return response.data;
  },
};

import React from "react";
import { Modal, Row, Col } from "reactstrap";
import { ExitModal } from "../../../components/exitModal";
import {
  Alert
} from "reactstrap";
export const ValidacaoCelular = ({ modalCelular, setModalCelular, handleInputChange, setCodigo1, codigo1, setCodigo2, codigo2, setCodigo3, codigo3, setCodigo4, codigo4, handleDisparoCelular, handleValidarCelular, input1Ref, input2Ref, input3Ref, input4Ref, celular, loading, start, time }) => {
  return (
    <Modal isOpen={modalCelular} centered={true}>
      <div className="modal-header" style={{ border: 'none' }}>
        <ExitModal setModal={setModalCelular} />

      </div>
      <div className="" style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 20, fontWeight: 300 }}>
        <p style={{ color: '#092140', textAlign: 'center', fontWeight: 300 }}>Valide seu Celular</p>
        <Alert color="success" style={{ marginTop: "13px", textAlign: 'center', fontWeight: 300 }}>
          Digite o código enviado por SMS ou Whatsapp. <br />
          {celular}
        </Alert>
        {/* <p style={{ fontSize: 15,textAlign:'center' }}>
            Digite o código enviado para seu WhatsApp ou SMS{" "}<br/>
       
          </p> */}
           <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row' }}>
          <Col lg={3} style={{ flex: 1 }} >
            <input
              className="form-control"
              value={codigo1}
              ref={input1Ref}
              onChange={(e) => {
                handleInputChange(e);
                setCodigo1(e.target.value);
              }}
              style={{ textAlign: "center" }}
              maxLength={1}
            />
          </Col>
          <Col lg={3} style={{ flex: 1 }}>
            <input
              className="form-control"
              value={codigo2}
              ref={input2Ref}
              onChange={(e) => {
                handleInputChange(e);
                setCodigo2(e.target.value);
              }}
              style={{ textAlign: "center" }}
              maxLength={1}
            />
          </Col>
          <Col lg={3} style={{ flex: 1 }}>
            <input
              className="form-control"
              value={codigo3}
              ref={input3Ref}
              onChange={(e) => {
                handleInputChange(e);
                setCodigo3(e.target.value);
              }}
              style={{ textAlign: "center" }}
              maxLength={1}
            />
          </Col>
          <Col lg={3} style={{ flex: 1 }}>
            <input
              className="form-control"
              value={codigo4}
              ref={input4Ref}
              onChange={(e) => {
                handleInputChange(e);
                setCodigo4(e.target.value);
              }}
              style={{ textAlign: "center" }}
              maxLength={1}
            />
          </Col>
        </Row>
      </div>

      <Row>

        <Col lg={12} style={{ marginTop: 20, display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }} >
          <button className="btn btn-default mb-2" disabled={loading ? loading : start ? true : false} onClick={() => { handleDisparoCelular() }}  >{loading ? 'Reenviar codigo' : start ? `00:${time < 10 ? '0' + time : time}` : `Reenviar código`}</button>

          <button className="btn btn-primary " style={{ marginBottom: 20, width: 160 }} disabled={loading} onClick={() => { handleValidarCelular(); }}  >
          Avançar
          </button>
        </Col>
      </Row>

      {/* <div className="" style={{ border: 'none', paddingRight: 25, marginTop: -9, display: 'flex', alignContent: 'center', flexDirection: 'column' }}>
        <button
          className="btn btn-link"
          disabled={loading ? loading : start ? true : false}
          onClick={() => {
            handleDisparoCelular();
          }}
          style={{ color: 'red', fontWeight: 300 }}
        >
          {loading
            ? "Reenviar código"
            : start
              ? `00:${time < 10 ? "0" + time : time}`
              : `Reenviar código`}
        </button>
        <button
          className="btn btn-primary"
          disabled={loading}
          onClick={() => {
            handleValidarCelular();
          }} >
          Avançar
        </button>
      </div> */}
    </Modal>
  )
}
import React, { useEffect, useState } from "react";
import { useCookies } from 'react-cookie';

import Mobile from "./utils/operadoras";
import Icone from "../../assets/icones/icone.png"
import Ramos from "./utils/ramos";
function FeatureSection({ id, setOperadora,operadoras,ramos, descricao,logo,titulo,datas}) {
  const [cookie,setCookie] = useCookies(['authUser'])
  const obj =cookie.authUser





  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [id]);
  return (
    <section className="featured-section bgs-cover pt-20 rpt-150 pb-20 rpb-100" style={{ minHeight: 700 }}>
      <div className="container">
        <div style={{ display: 'flex', flexDirection: isDesktop ? 'row' : 'column', justifyContent: 'space-between', alignItems: 'center' }} >
          {logo &&
            <div style={{ flex: 1,display:'flex',alignItems:"center",justifyContent:'center'}} >
              <img src={`https://server.corpar.com.br/imagens/${logo}`} width={110} />
            </div>
          }

          <div style={{ flex: 5, textAlign: 'justify' }} >
            <p style={{marginTop:15,fontSize:20}}>{descricao}</p>
          </div>
        </div>
        {ramos && ramos?.length > 0 && <Ramos list={ramos} type='ramos' />}
        {operadoras && operadoras?.length > 0 && <Mobile list={operadoras} type='empresas' />}

        {!(ramos ) || !(operadoras ) && (
          <div className={`col-lg-12 col-sm-12 item `} style={{ marginTop: "10px", minHeight: 299, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <img src={Icone} className="rotating-image" />
          </div>
        )}
        <div className="feature-btn text-center mt-20"></div>
      </div>
    </section>
  );
}

export default FeatureSection;

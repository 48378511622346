import React, { useEffect, useState } from "react";
import {DropdownItem,DropdownMenu,DropdownToggle,Modal,UncontrolledDropdown} from "reactstrap";
import { Api } from "../../../api/apiCotacoes";

export const Agendamentos = ({modalAgendamento,setModalAgendamento,id,handleLimpar}) => {
  const [list, setList] = useState([]);
  const handleList = async () => {
    let list = await Api.agendamentos({ idcotacao: id });
    setList(list.list);
  };
  const handleExcluir = async (idAgendamento) => {
    let list = await Api.excluirAgendamentos({
      idcotacao: id,
      id: idAgendamento
    });
    setList(list.list.list);
  };
  useEffect(() => {
    handleList();
  }, [modalAgendamento]);
  return (
    <Modal id="modal-center3"  size="md"  isOpen={modalAgendamento} centered={true} >
      <div className="modal-header">
        <h5 className="modal-title mt-0">Agendamentos</h5>
        <button type="button"  className="close"  data-dismiss="modal" aria-label="Close" 
        onClick={() => {
            setModalAgendamento(false);
            handleLimpar(true);
          }}
         
        >
          <span aria-hidden="true">&times;</span>
        </button>
        
      </div>
      <div className="modal-body">
        <div className="table-responsive" style={{ marginTop: 20, minHeight: 250 }} >
          <table className="table">
            <thead>
              <tr>
                <th>Observação</th>
                <th>Data</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody>
              {list &&
                list.map((item, index) => (
                  <tr key={index}>
                    <td>{item.obs} </td>
                    <td>{item.data} </td>

                    <td>
                      <UncontrolledDropdown>
                        <DropdownToggle className="btn btn-secondary" type="button" tag="a" >
                          <i className=" bx bx-wrench"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem style={{ color: "red" }} onClick={() => { handleExcluir(item.id);  }} data-toggle="modal"
                            data-target=".bs-example-modal-center" to="#" >
                            <i className="  bx bx-trash"></i> Excluir
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </Modal>
  );
};

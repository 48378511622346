import React, { useEffect, useState } from "react";
import { Api } from "../../api";
import { useCookies } from 'react-cookie';

export default function MainSection() {
  const [listOriginal, setListOriginal] = useState();
  const [list, setList] = useState();
  const [ultimas, setUltimas] = useState();
  const [categorias, setCategorias] = useState();
  const [cookie] = useCookies(['authUser'])
  const obj = cookie.authUser
  
 

  const handleDados = async () => {
    let json = await Api.blogs();
    let list = json?.list.list
    setListOriginal(list)
    let categorias = json?.list.categorias
    list = list?.filter((item) => !obj?.id ? item.tipo == 1 : 1 == 1)
    if (list?.length > 0) {
      setList(list);
      setUltimas(list.filter((item, index) => index <= 2))
    }
    if (categorias?.length > 0) {
      setCategorias(categorias);
    }
  };
  useEffect(() => {
    handleDados();
  }, []);
  useEffect(() => {
    handleVisualizar();
  }, [list]);
  const handleVisualizar = async () => {
    let id =obj?.id
    if (id) {
      id = JSON.parse(id)
    }
    list?.map(async (item) => {
      await Api.Visualizacao({ iduser: id, idblog: item.id })
    })
  }

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section className="blog-page-area bg-lighter">
      <div className="container" >
        <div className="blog-standard-content pt-20  pb-110 " style={{ backgroundColor: '#F6F7F9', ...(list?.length <= 1 && { height: '100vh' }) }}>
          {list &&
            list.map((item, index) => (
              <div key={index} style={{ width: '100%', borderRadius: 10, borderBottom: '2px solid #E5EEF6', padding: 15 }}>
                <div className="blog-header" style={{ top: 0 }}>
                  <ul className="post-meta-item mr-15">
                    <li>
                      <i className="fas fa-calendar-alt"></i>
                      <a href="#">{item?.datainicio}</a>
                    </li>
                  </ul>
                </div>
                <div className="blog-standard-item wow fadeInUp delay-0-2s" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: 10, flexDirection: isDesktop ? 'row' : 'column' }}  >

                  <div className="" style={{ flex: 1, display: 'flex', alignItems: 'center' ,marginBottom:20}}>
                    {item?.imagem && (
                      <img src={`https://server.corpar.com.br/imagem/${item?.imagem}`} width={500}  />
                    )}
                    {(item?.video || item.url) && (
                      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }} >
                        <iframe width="280" height="220" style={{ borderRadius: 10 }} src={url || `https://server.corpar.com.br/video/` + item.video} title="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                      </div>
                    )}
                  </div>
                  <div style={{ flex: 1, display: "flex", alignItems: 'flex-start', flexDirection: "column", justifyContent: "space-around", height: '400px', overflowY: 'auto' }}  >

                    <h3>
                      {item?.titulo}
                    </h3>
                    <div id="descricao" dangerouslySetInnerHTML={{ __html: item?.descricao?.replace(/\n/g, "<br>") }} ></div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </section>
  );
}

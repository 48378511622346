
import React, { useEffect, useRef, useState } from "react";

function TermosDeUso() {

  return (
    <section className="featured-section bgs-cover pt-20  pb-20 rpb-100" style={{ minHeight: 850 }}>
      <div className="container"  >



        <div className="feature-btn  mt-20" style={{ textAlign: 'justify' }}>

          {`Seja bem-vindo(a) ao Corretor Parceiro. Seu portal de apoio a vendas! <br/>

 
          Antes de aproveitar tudo o que o nosso Portal Web (“Portal”) tem a oferecer, é fundamental que você compreenda e concorde com as regras que regem a sua utilização. Estes Termos e Condições de Uso (“Termos”) estabelecem as condições gerais para o acesso e uso do Portal, incluindo os recursos, funcionalidades e serviços disponibilizados aos usuários (“Você”).<br/>

          Ao utilizar nosso Portal e serviços, Você concorda automaticamente e integralmente, com estas condições, por isso, leia atentamente estes Termos. Caso não concorde com algo, por favor, considere não usar os nossos serviços. É muito importante para nós que Você se sinta seguro e informado a todo momento.

          

          1. Aceitando os Termos
          Ao navegar e usar o Portal, Você concorda automaticamente com nossas regras e condições. Estamos sempre procurando melhorar, então estes Termos podem mudar de vez em quando. Se fizermos alterações significativas, vamos postar as atualizações aqui no Site. Continuar usando o Site após essas mudanças significa que você aceita os novos termos.
          
          2. Serviços oferecidos
          Os serviços do Portal Corretor Parceiro são oferecidos pela Natuseg Assessoria e Corretora de Seguros Ltda., sociedade empresária limitada, inscrita no CNPJ/ME sob o n. 03.136.742/0001-37, titular do domínio e da propriedade intelectual sobre o software, website, aplicativos, conteúdos e demais ativos relacionados a este Portal.

          O Corretor Parceiro oferece uma gama completa de ferramentas de vendas, desenvolvidas para otimizar o trabalho dos corretores, facilitar o atendimento aos clientes e aumentar a eficiência no fechamento de negócios. Confira os principais serviços disponíveis:

          Nossas ferramentas são divididas em quatro áreas para atender suas necessidades de forma personalizada:

          ● Simular Cotações de Preços
          ○ Cotações de diversos tipos de produtos, como planos de saúde, odontológicos, seguros em geral e outros, com rapidez e praticidade.
          ○ Tabelas de Preços Personalizadas, ajustadas conforme o perfil de seus clientes.
          ○ Comparador de Redes de Planos de Saúde, facilitando a escolha ideal para seus clientes.
          ● Links para Vendas Online
          ○ Links de vendas para transmitir propostas de diversos tipos de produtos, como planos de saúde, odontológicos, seguros em geral e outros.
          ● Tabelas e Materiais de Vendas
          ○ Informações organizadas, completas ou resumidas sobre diversos tipos de produtos, como planos de saúde, odontológicos, seguros em geral e outros.
          ● Marketing e Comunicação
          ○ Comunicados: Atualizações constantes sobre produtos, serviços e promoções.
          ○ Agenda de Treinamentos: Eventos e capacitações para aprimorar suas estratégias e conhecimentos.
          ○ Materiais de Divulgação: Folders, apresentações e outros conteúdos visuais para apoiar suas vendas.
          As cotações apresentadas, material de divulgação e redes de prestadores estão sujeitos a alterações a qualquer momento por parte das operadoras e seguradoras, observadas as suas respectivas políticas comerciais. Assim, os cálculos, tabelas de preços e propostas geradas no Portal não possuem caráter vinculativo. Sugerimos sempre validar as informações antes de apresentá-las ao cliente.

          Nós não garantimos a atualização instantânea do conteúdo ofertado em nosso Portal, não garantimos os valores apresentados, nem nos responsabilizamos por qualquer decisão tomada com base no uso dos nossos serviços.

          

          3. Acesso e uso do Portal
          O Portal oferece acesso a uma variedade de serviços, permitindo que Você acesse informações relevantes e algumas ferramentas sem a necessidade de um cadastro prévio. No entanto, para usufruir de funcionalidades específicas e personalizadas, como acesso a materiais exclusivos e a realização de cotações, é necessário efetuar um cadastro.

          

          3.1. Cadastro

          Para efetuar o cadastro, Você deverá fornecer informações como CPF, data de nascimento, nome completo, e-mail, número de celular e CEP da região de trabalho. Esses dados nos ajudam a personalizar sua experiência e enviar informações relevantes para Você.

          Ao cadastrar-se Você se compromete a fornecer informações verdadeiras e a manter a confidencialidade das suas credenciais. O cadastro de informações falsas pode gerar inconsistência na prestação dos serviços, bem como impactar ou interromper o seu acesso.  

          A qualquer tempo, sem aviso prévio, o Portal poderá suspender, cancelar ou interromper o acesso aos serviços, respeitadas as condições da legislação aplicável.

          

          3.2. Uso Ético

          Ao utilizar o Corretor Parceiro Você se compromete a:

          ● Respeitar a legislação aplicável;
          ● Não realizar práticas que comprometam o funcionamento da plataforma;
          ● Usar as informações de forma responsável e ética, respeitando as diretrizes estabelecidas neste Termo e a legislação vigente.
          Ressaltamos que o Portal se reserva o direito de monitorar o uso das funcionalidades para garantir a integridade e a segurança da plataforma.

          

          4. Responsabilidades do Usuário
          ● Utilizar as funcionalidades do Portal apenas para fins legais e em conformidade com a legislação vigente, abstendo-se de realizar qualquer atividade que possa ser considerada ilícita ou prejudicial a terceiros;
          ● Não realizar práticas fraudulentas, enganosas ou que possam comprometer a integridade do software e de seus usuários, incluindo a criação de contas falsas ou a manipulação de informações;
          ● Manter a confidencialidade de suas credenciais de acesso, responsabilizando-se por todas as atividades realizadas em sua conta;
          ● Não utilizar a conta de terceiros sem autorização;
          ● Informar imediatamente, através dos nossos canais oficiais constantes no próprio Portal, sobre qualquer uso não autorizado de sua conta ou qualquer outra violação de segurança;
          ● Não modificar, adaptar ou hackear a plataforma, nem criar contas por meio de métodos automáticos;
          ● Utilizar as informações obtidas em nossa plataforma de forma ética, respeitando os direitos de propriedade intelectual de terceiros.
          

          5. Limitações e exclusão de garantias e responsabilidades do Corretor Parceiro
          O Portal não se responsabiliza por danos diretos, indiretos, incidentais ou consequentes decorrentes do uso das ferramentas da plataforma, incluindo, mas não se limitando a:

          ● Perda de lucros ou oportunidades: O Corretor Parceiro não é responsável por qualquer perda de lucros, receitas ou oportunidades de negócios que possam resultar do uso dos serviços ou da incapacidade de utilizá-los;
          ● Erros ou desatualização de informações: As cotações e informações disponibilizadas na plataforma são baseadas nas informações fornecidas pelas operadoras de saúde e podem ser alteradas a qualquer momento. O Corretor Parceiro não garante a precisão, integridade ou atualização dessas informações, sendo recomendável que Você sempre valide os dados diretamente com as operadoras;
          ● Consequências de Decisões: O Corretor Parceiro não se responsabiliza por qualquer perda ou dano resultante de decisões tomadas com base nas informações disponíveis em nossa plataforma, sendo Você o único responsável por qualquer ação tomada com base em tais informações.
          Além disso, o Corretor Parceiro não garante:

          ● A precisão, integridade ou atualidade das informações fornecidas, uma vez que as operadoras e seguradoras podem realizar modificações a qualquer momento;
          ● A continuidade do acesso aos serviços sem interrupções ou erros;
          ● A correção imediata de quaisquer falhas ou defeitos no sistema do Portal.
          

          6. Links de terceiros
          O Corretor Parceiro não se responsabiliza pelo conteúdo ou práticas de sites de terceiros. Caso você tenha reclamações ou dúvidas sobre serviços de terceiros, elas devem ser direcionadas aos canais apropriados dos respectivos fornecedores.

          Recomendamos que Você leia atentamente os termos de uso de qualquer site externo antes de utilizá-lo.

          

          7. Propriedade intelectual
          Todo e qualquer conteúdo do Corretor Parceiro, como imagem, fotografia, slogan, marca, texto, declaração e interface de usuário, presentes no nosso Portal, constituem propriedade intelectual única e exclusiva da empresa proprietária do Portal ou se encontram devidamente autorizados e licenciados por terceiros. O uso não autorizado pode resultar em providências legais.

          Ao acessar nosso Portal, Você recebe uma licença limitada, não exclusiva e revogável para visualizar e usar o conteúdo para fins pessoais e não comerciais. Isso implica que qualquer reprodução, distribuição, transmissão ou modificação do conteúdo, sem a devida autorização prévia e por escrito do Corretor Parceiro, é estritamente proibida. Tal restrição visa proteger os direitos de propriedade intelectual associados aos materiais disponibilizados, assegurando que sua utilização não infrinja os direitos dos criadores ou detentores desses direitos, além de promover um ambiente de respeito e valorização da criatividade e inovação.

          

          8. Privacidade e proteção de dados pessoais
          Os dados pessoais serão tratados nos termos da Lei Geral de Proteção de Dados (LGPD) e conforme a nossa Política de Privacidade, disponível em [https://corretorparceiro.com.br/politicadeprivacidade].

          

          9. Dúvidas e esclarecimentos
          Para esclarecimentos sobre estes Termos, entre em contato conosco através dos canais disponíveis em nosso Portal. Estamos sempre à disposição para atender suas dúvidas, sugestões e solicitações de informações.

          

          10. Modificações destes termos
          O Corretor Parceiro se reserva o direito de modificar estes Termos de Uso a qualquer momento. As alterações serão publicadas no nosso Portal e a continuidade do uso indicará a aceitação das novas condições. Recomendamos que você revise periodicamente estes Termos para estar ciente de qualquer modificação.

          

          11. Disposições gerais
          Estes Termos constituem o entendimento integral entre Você e o Corretor Parceiro sobre o uso do Portal e substituem quaisquer acordos ou entendimentos anteriores, sejam verbais ou escritos. Se qualquer disposição destes Termos for considerada inválida ou inaplicável, as demais disposições permanecerão em pleno vigor e efeito.

          

          12. Lei aplicável e Foro
          Estes Termos de Uso serão regidos, interpretados e executados de acordo com as leis da República Federativa do Brasil, independentemente dos conflitos dessas leis com lei de outros estados ou países, sendo competente o Foro da Comarca de Salvador - Bahia, no Brasil, para dirimir qualquer dúvida decorrente deste instrumento, com renúncia expressa a qualquer outro, por mais privilegiado que seja.`}
        </div>
      </div>
    </section>
  );
}
export default TermosDeUso;

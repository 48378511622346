import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Api } from "../../api";
import Mobile from "./utils/mobile";
import Desktop from "./utils/desktop";
import Icone from "../../assets/icones/icone.png"
import { Api as ApiSite } from "../../api";
import { GestorModal } from "../components/modal";
import { useCookies } from 'react-cookie';

function FeatureSection(val) {
  const [cookie,setCookie] = useCookies(['authUser'])
  const obj =cookie.authUser
  const [datas, setDatas] = useState({});

  const [estados, setEstados] = useState([])
  const [estadosSelect, setEstadosSelect] = useState('')
  useEffect(() => {
    handleEstados(val?.plano);
  }, [val?.plano])

  const handleEstados = async (plano) => {
    let tipo = 0
    switch (plano) {
      case "saude":
        tipo = 1
        break;
      case "odontologico":
        tipo = 2
        break;
      case "pet":
        tipo = 3
        break;
      case "uti":
        tipo = 4
        break;
      case "card":
        tipo = 5
        break;

      default:
        break;
    }
    if (tipo) {
      let value = await Api.estadosSaude(tipo)
      setEstados(value.list)
    }

  }
  const handleOperadora = async () => {

    if (val.plano == "saude" && estadosSelect && estadosSelect != undefined && estadosSelect != 'undefined') {
      setDatas([]);
      let list = await Api.operadoraSaude({ estado: estadosSelect });
      let valores = list.list.map((item) => {
        return { id: item.id, title: item.nome, image: item.imagem, filter: item.tipopessoa };
      });
      setDatas({ valores, estado: estadosSelect });
    }

    if (val.plano == "odontologico" && estadosSelect && estadosSelect != undefined && estadosSelect != 'undefined') {
      setDatas({});
      let list = await Api.operadoraOdontologico({ estado: estadosSelect });

      let valores = list.list.map((item) => {
        return { id: item.id, title: item.nome, image: item.imagem, filter: item.tipopessoa };
      });
      setDatas({ valores, estado: estadosSelect });
    }
    if (val.plano == "pet" && estadosSelect && estadosSelect != undefined && estadosSelect != 'undefined') {
      setDatas({});
      let list = await Api.operadoraPet({ estado: estadosSelect });

      let valores = list.list.map((item) => {
        return { id: item.id, title: item.nome, image: item.imagem, filter: 'PET' };
      });
      setDatas({ valores, estado: estadosSelect });
    }

    if (val.plano == "card" && estadosSelect && estadosSelect != undefined && estadosSelect != 'undefined') {
      setDatas({});
      let list = await Api.operadoraCARD({ estado: estadosSelect });

      let valores = list.list.map((item) => {
        return { id: item.id, title: item.nome, image: item.imagem, filter: val.plano?.toUpperCase() };
      });

      setDatas({ valores, estado: estadosSelect });
    }
    if (val.plano == "uti" && estadosSelect && estadosSelect != undefined && estadosSelect != 'undefined') {
      setDatas({});

      let list = await Api.operadoraUTI({ estado: estadosSelect });
      let valores = list.list.map((item) => {
        return { id: item.id, title: item.nome, image: item.imagem, filter: val.plano?.toUpperCase() };
      });
      setDatas({ valores, estado: estadosSelect });
    }
    // if (val.plano == "consorcio" && estadosSelect && estadosSelect != undefined && estadosSelect != 'undefined') {
    //   setDatas({});
    //   let list = await Api.operadoraConsorcio({ estado: estadosSelect });

    //   let valores = list.list.map((item) => {
    //     return { id: item.id, title: item.nome, image: item.imagem, filter: item.tipopessoa };
    //   });
    //   setDatas({ valores, estado: estadosSelect });
    // }
  };

  useEffect(() => {
    handleOperadora()
  }, [val?.plano, estadosSelect, setEstadosSelect]);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [val?.plano]);
  const [gestorModal, setGestorModal] = useState()
  const handleModal = async () => {
    let list = await ApiSite.gestorModal('Tabela');
    setGestorModal(list.dados)

  };
  useEffect(() => {
    handleModal()
  }, [])
  return (
    <section className="featured-section bgs-cover pt-20 rpt-150 pb-20 rpb-100" style={{ minHeight: 700 }}>
      <div className="container">
        <div className="row" style={{ marginBottom: 25 }}>
          <div className="col-lg-12 ">
            <div className="col-12 col-lg-4 " style={{ marginLeft: 'auto', marginRight: 'auto' }} >
              <select className="form-control" name="estados" value={estadosSelect} onChange={(e) => { setEstadosSelect(e.target.value) }} >
                <option value=''>Escolha o Estado</option>
                {estados && estados.map((item) => (
                  <option value={item.id} key={item}>{item.nome}</option>
                ))}
              </select>
            </div>
          </div>
        </div>
        {['pet', 'uti', 'card'].includes(val?.plano) ? (<Mobile datas={datas} plano={val?.plano} />) :
          (isDesktop)
            ? datas.estado && <Desktop datas={datas} />
            : datas.estado && <Mobile datas={datas} />
        }
        {!datas.estado && estadosSelect != '' && (
          <div className={`col-lg-12 col-sm-12 item `} style={{ marginTop: "10px", minHeight: 299, display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
            <img src={Icone} className="rotating-image" />
          </div>
        )}
        <div className="feature-btn text-center mt-20"></div>
      </div>
      <GestorModal dados={gestorModal} />
    </section>
  );
}

export default FeatureSection;

import React, { useState, useEffect } from "react";
import { useCookies } from "react-cookie";

const CookieConsent = () => {
  const [cookies, setCookie] = useCookies(["cookieConsent"]);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Verifica se o cookie de consentimento existe
    if (!cookies.cookieConsent) {
      setIsVisible(true);
    }
  }, [cookies]);

  const acceptCookies = () => {
    // Define o cookie com 1 ano de validade
    setCookie("cookieConsent", "true", { path: "/", maxAge: 365 * 24 * 60 * 60, sameSite: "strict", secure: true });
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div style={styles.container}>
        <div style={styles.content}>
          <p style={styles.text}>
            Este site utiliza cookies para melhorar sua experiência de navegação. Clique em "Aceitar" para continuar.
          </p>
          <button
            onClick={acceptCookies}
            style={styles.button}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = "#ffffff";
              e.target.style.color = "#2278F3";
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "#2278F3";
              e.target.style.color = "#ffffff";
            }}
          >
            Aceitar
          </button>
        </div>
      </div>
    )
  );
};

const styles = {
  container: {
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    background: "rgba(34,120,243,0.8)",
    boxShadow: "0 -2px 10px rgba(0, 0, 0, 0.2)",
    zIndex: 1000,
    display: "flex",
    justifyContent: "center",
    padding: "15px 0",
  },
  content: {
    maxWidth: "800px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection:'column',
    width: "90%",
    flexWrap: "wrap",
  },
  text: {
    color: "#ffffff",
    fontSize: "16px",
    lineHeight: "1.5",
    textAlign: "center",
    marginBottom: "10px",
  },
  button: {
    backgroundColor: "#2278F3",
    color: "#ffffff",
    padding: "10px 20px",
    border: "none",
    borderRadius: "6px",
    cursor: "pointer",
    fontSize: "16px",
    fontWeight: "bold",
    transition: "all 0.3s ease",
  },
};

export default CookieConsent;

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Api } from "../../api";
import BacktoTopCom from "../../components/helpers/BackToTopCom";
import Footer from "./footer";
import Header from "./Header";
import Sobre from "./conteudo/sobre";
import Galeria from "./conteudo/galeria";
import Contato from "./conteudo/contato";
import Banner from "./conteudo/banner";
import Inscreva from "./conteudo/inscreva";
import Testemunios from "./conteudo/testemunios";
import Logo from "./conteudo/logos";
import { GestorModal } from "../components/modal";
import { baseUrlApi,baseUrlApp,baseUrlSite } from "../../api/baseUrl";
import { useCookies } from "react-cookie";

import CookieConsent from "../components/cookies";
export default function HomeThree() {
  const [dados, setDados] = useState([]);
  const [ordem, setOrdem] = useState([]);
 
  const { id } = useParams();
  const handleOrdem = async () => {
    let list = await Api.estrutura();
    if (list?.list.length) {
      setOrdem(list?.list);
    }
  };
  const handleModal = async () => {
    let list = await Api.gestorModal('Home');
    setDados(list.dados)
  };
  useEffect(() => {
    handleOrdem();
    handleModal()
    var url = window.location.href;
    url = url.split("/");
    if (url.includes("login") && url.includes("cotacoes") && id) {
      handleDadosBuscar(id);
    }
  }, []);


  return (
    < >
      <Header />
      {ordem &&
        ordem?.map((item) => (
          <div key={item.id}>
            {item.id == 10 && <Banner />}
            {item.id == 12 && <Logo />}
            {item.id == 14 && <Sobre />}
            {item.id == 11 && <Galeria />}
            {item.id == 15 && <Testemunios />}
            {item.id == 16 && <Inscreva />}
            {item.id == 18 && <Contato />}
          </div>
        ))}
      <GestorModal dados={dados} />
      <Footer className="footer-home-three pt-150" />
      <BacktoTopCom />
      <CookieConsent />
    </>
  );
}

import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row } from "reactstrap";
import { baseUrlApi } from "../../api/baseUrl";
import { HiOutlineX } from "react-icons/hi";
import { ImExit } from "react-icons/im";
import { Exit } from "./exit";
import { useCookies } from "react-cookie";
export const GestorModal = ({ dados }) => {
    const [cookies, setCookie] = useCookies();


    function executarRotinasIntervaladas(rotinas) {
        const hoje = new Date().toISOString().split('T')[0];
        let rotinaExecutada = false;

        rotinas?.forEach((item) => {
            if (item?.visualizarsempre === 'SIM') {
                executarFuncao(item);
            } else {
                if (rotinaExecutada) return;

                const nome = item.nome;
                const frequencia = item.frequencia || 5; // Frequência padrão caso não seja fornecida

                const chaveData = `ultimaData_${nome}`;
                const chaveAcessos = `numeroAcessos_${nome}`;
                const chaveIntervalo = `intervaloAtual_${nome}`;
                const chaveExecucoes = `execucoesHoje_${nome}`;
                const ultimaData = cookies[chaveData] || null;
                let acessos = parseInt(cookies[chaveAcessos] || "0", 10);
                let intervaloAtual = parseInt(cookies[chaveIntervalo] || "1", 10); // Começa com 1 no ciclo
                let execucoesHoje = parseInt(cookies[chaveExecucoes] || "0", 10);

                if (ultimaData !== hoje) {
                    acessos = 0;
                    execucoesHoje = 0;
                    intervaloAtual = 1; // Reinicia o ciclo
                    setCookie(chaveData, hoje, { path: "/", maxAge: 365 * 24 * 60 * 60, sameSite: "strict", secure: true });
                    // localStorage.setItem(chaveData, hoje);
                }

                acessos += 1;
                setCookie(chaveAcessos, acessos, { path: "/", maxAge: 365 * 24 * 60 * 60, sameSite: "strict", secure: true });
                // localStorage.setItem(chaveAcessos, acessos);

                if (execucoesHoje >= Math.ceil(24 * 60 / frequencia)) {
                    return;
                }
                if (acessos === intervaloAtual) {
                    executarFuncao(item);
                    execucoesHoje += 1;
                    // localStorage.setItem(chaveExecucoes, execucoesHoje);
                    setCookie(chaveExecucoes, execucoesHoje, { path: "/", maxAge: 365 * 24 * 60 * 60, sameSite: "strict", secure: true });
                    rotinaExecutada = true;

                    // Avança para o próximo intervalo no ciclo fixo
                    intervaloAtual = calcularProximoIntervaloFixos(intervaloAtual);
                    // localStorage.setItem(chaveIntervalo, intervaloAtual);
                    setCookie(chaveIntervalo, intervaloAtual, { path: "/", maxAge: 365 * 24 * 60 * 60, sameSite: "strict", secure: true });
                }
            }
        });
    }

    /**
     * Calcula o próximo intervalo no ciclo fixo (1 → 5 → 15 → 25 → 5).
     */
    function calcularProximoIntervaloFixos(intervaloAtual) {
        const ciclosFixos = [1, 5, 15, 25, 5];
        const indexAtual = ciclosFixos.indexOf(intervaloAtual);

        if (indexAtual === -1 || indexAtual === ciclosFixos.length - 1) {
            return 5; // Reinicia para 5 se chegar ao final
        }

        return ciclosFixos[indexAtual + 1]; // Avança para o próximo intervalo no ciclo
    }



    // Função a ser executada
    function executarFuncao(item) {
        // console.log({item})
        if (item.imagem || item.video || item.descricao || item.url) {
            setImagem(item.imagem)
            setVideo(item.video)
            setUrl(item.url)
            setDescricao(item.descricao)
            setModal(true)
        }


        // console.log("Função executada!");
    }
    const [modal, setModal] = useState(false)
    useEffect(() => {

        executarRotinasIntervaladas(dados)

    }, [dados])
    const modalRef = useRef();
    const [imagem, setImagem] = useState("")
    const [video, setVideo] = useState("")
    const [descricao, setDescricao] = useState("")
    const [url, setUrl] = useState("")
    useEffect(() => {
        // Função para detectar clique fora do modal
        const handleClickOutside = (event) => {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setModal(false); // Fecha o modal se o clique foi fora dele
            }
        };

        // Adiciona o evento de clique ao documento
        document.addEventListener('mousedown', handleClickOutside);

        // Remove o evento quando o componente é desmontado
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [setModal]);

    if (!modal) return null;
    return (
        <Modal
            id="modal-center3"
            size="xl"
            isOpen={modal}
            centered={true}
            style={{ maxWidth: 420 }}>
            <div className="modal-body" ref={modalRef} >
              <Exit modal={modal} setModal={setModal} top={25} />
                {(url || video) &&
                    <div style={{ backgroundColor: '#fff', overflow: 'hidden', width: '100%', height: '400px', position: 'relative' }}>
                        <video controls autoPlay
                            style={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: '#fff' }} >
                            <source src={url || `https://server.corpar.com.br/video/` + video} type="video/mp4" />
                        </video>
                    </div>
                }


                {imagem &&
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: 20 }}>
                        <img src={`https://server.corpar.com.br/imagem/` + imagem} width={'100%'} />
                    </div>
                }
                {descricao ?
                    <p style={{ textAlign: 'justify', padding: '10px', lineHeight: 1 }} dangerouslySetInnerHTML={{ __html: descricao }} >
                    </p>
                    : ""}



            </div>

        </Modal >
    )
}
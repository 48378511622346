import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Api } from "../../api";
import { useCookies } from 'react-cookie';

import Apple from "../../assets/images/lojas/apple.png";
import Android from "../../assets/images/lojas/android.png";
import { Modal } from "reactstrap";
import { ExitModal } from "../components/exitModal";
export default function FooterHomeTwo({ className }) {
  const [itemSize, setSize] = useState("");
  const [item, setItem] = useState("");
  const [modalDados, setModalDados] = useState();
  const [cookie] = useCookies(['authUser'])
  const obj =cookie.authUser
  const handler = (e, value) => {
    // e.preventDefault();
    const getItems = document.querySelectorAll(`#${value} li`).length;
    if (getItems > 0) {
      if (item !== value) {
        setSize(`${20 * getItems}px`);
        setItem(value);
      } else {
        setItem("");
      }
    }
  };

 
  const navigate = useNavigate();
  const [modalApagar, setModalApagar] = useState();
  const [estadosSaude, setEstadosSaude] = useState();
  const [estadosOdonto, setEstadosOdonto] = useState();
  const [loadingSaude, setLoadingSaude] = useState(false);
  const [loadingOdonto, setLoadingOdonto] = useState(false);
  const [escolha, setEscolha] = useState(0);
  const [escolhasub, setEscolhaSub] = useState(0);
  const [modalLogin, setModalLogin] = useState(false);
  const handleEstadosSaude = async () => {
    setLoadingSaude(true);
    let list = await Api.estadosSaude();
    setEstadosSaude(list.list);
    setLoadingSaude(false);
  };
  const handleEstadosOdonto = async () => {
    setLoadingOdonto(true);
    let list = await Api.estadosOdontologico();
    setEstadosOdonto(list.list);
    setLoadingOdonto(false);
  };
  useEffect(() => {
    handleEstadosSaude();
    handleEstadosOdonto();
  }, []);
  const handleLink = () => {
    window.open(`${process.env.REACT_APP_SITE}/login/${obj?.id}/${obj?.token}`, "_blank");

  }
  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  const handleClickLojas = () => {
    setModal(true)
  }
  const [modal, setModal] = useState(false)
  return (
    <footer
      className={`footer-two text-white `}
      style={{ backgroundColor: '#082140', marginTop: 'auto', display: 'flex', flexDirection: 'column', position: 'absolute', width: '100%' }}>

      <div className="copyright-area">
        <div className="" style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column' }}>

          <div className="copyright-inner " >

            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', marginBottom: 10 }} >
              <img src={Apple} width={150} style={{ cursor: 'pointer' }} onClick={() => { handleClickLojas() }} />
              <img src={Android} width={150} style={{ cursor: 'pointer' }} onClick={() => { handleClickLojas() }} />
            </div>

            <p style={{ textAlign: 'center', width: '100%' }}>copyright 2024 Corretor Parceiro.</p>
          </div>
        </div>
      </div>
      <Modal isOpen={modal} size='md' centered={true}>
        <div className="modal-header" style={{ border: '0px' }}>
          <ExitModal setModal={setModal} />

        </div>
        <div className="modal-body" style={{ border: '0px' }}>
          <div style={{ display: 'flex', justifyContent: 'center', fontSize: '20px' }}>Nosso aplicativo estará disponível em breve.</div>
        </div>
        <div className="modal-footer" style={{ border: '0px' }}>

        </div>
      </Modal>
    </footer>
  );
}

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Api } from "../../api";
import Mobile from "./utils/mobile";
import Icone from "../../assets/icones/icone.png"
import Ramos from "./utils/ramos";
import { useCookies } from 'react-cookie';

function FeatureSection({ id, setOperadora }) {
  const [datas, setDatas] = useState({});
  const val = null
  const [descricao, setDescricao] = useState('')
  const [logo, setLogo] = useState('')
  const [ramos, setRamos] = useState('')
  const [cookie,setCookie] = useCookies(['authUser'])
  const obj =cookie.authUser
  const handleOperadoraRamos = async () => {
    setDatas({});
    let list = await Api.RamosTemplate({ id });
    setRamos( list.list)
  };

  useEffect(() => {
    handleOperadoraRamos()
  }, [id]);

  const handleOperadora = async () => {
    setDatas([]);
    let list = await Api.operadora(id);
    let valores = list.list.map((item) => {
      return { id: item.id, title: item.nome, image: item.imagem, filter: item.tipopessoa, descricao: item.descricao };
    });
    setDescricao(valores[0]?.descricao)
    setLogo(valores[0]?.image)
    setOperadora(valores[0]?.title)
    setDatas({ valores, id: id, estado: obj?.estado });
  };
  useEffect(() => {
    handleOperadora()
  }, [id]);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [id]);
  return (
    <section className="featured-section bgs-cover pt-20 rpt-150 pb-20 rpb-100" style={{ minHeight: 700 }}>
      <div className="container">
        <div style={{ display: 'flex', flexDirection:isDesktop?'row':'column', justifyContent: 'space-between', alignItems: 'center', marginBottom: 50 }} >
          {logo &&
            <div style={{ flex: 1, marginRight: 20 }} >
              <img src={`https://server.corpar.com.br/imagens/${logo}`} />
            </div>
          }
          <div style={{ flex: 4, textAlign: 'justify',fontSize:20 }} >
            {descricao}
          </div>
        </div>
        {ramos && ramos?.length > 0 && <Ramos list={ramos} type='ramos' />}
        {!ramos && (
          <div  className={`col-lg-12 col-sm-12 item `} style={{ marginTop: "10px", minHeight: 299,display:'flex',justifyContent:'center',alignItems:'center' }}>
             <img src={Icone} className="rotating-image" />
          </div>
        )}
        <div className="feature-btn text-center mt-20"></div>
      </div>
    </section>
  );
}

export default FeatureSection;

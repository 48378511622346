import React, { useState } from "react";
import { Col, Label, Modal, Row } from "reactstrap";
import { ExitModal } from "../../components/exitModal";
 
export const Filtros = ({modalFiltro,setModalFiltro,handleCarregamento,municipios,setFiltro1,setFiltro2,setFiltro3,setFiltro4,setFiltro5,setFiltro6,filtro1,filtro2,filtro3,filtro4,filtro5,filtro6,handleFiltro,ramo}) =>{
    const [dados,setDados] = useState([])
    return(
        <Modal
        isOpen={modalFiltro}
        centered={true}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0">Filtros</h5>
    
          <ExitModal setModal={setModalFiltro} />
        </div>
        <div className="modal-body">
        <Row>
        <Col lg={6} >
          <div>
              <div className="mb-3">
                  <Label htmlFor="example-text-input" className="form-Label">Reembolso</Label>
                  <select name="possueReembolso" value={dados[0]} className="form-control" onChange={(e)=>{
                    setFiltro1(e.target.value=='Selecione'?'':e.target.value)
                    let dadosNew = [...dados]
                    dadosNew[0] = e.target.value
                    setDados(dadosNew)
                  }}>
                      <option value='' >Selecione</option>
                      <option value='Sim' >Sim</option>
                      <option value='Não' >Não</option>
                  </select>
                 
              </div>
          </div>
      </Col>
      <Col lg={6}>
          <div>
              <div className="mb-3">
                  <Label htmlFor="example-text-input" className="form-Label">Cobertura</Label>
                  <select name='cobertura' value={dados[1]} className="form-control" onChange={(e)=>{
                    setFiltro2(e.target.value=='Selecione' || e.target.value=='Todos'?'':e.target.value)
                    let dadosNew = [...dados]
                    dadosNew[1] = e.target.value
                    setDados(dadosNew)
                  }} >
                      <option>Selecione</option>
                      <option value='Todos' >Todos</option>
                      <option value='Ambulatorial' >Ambulatorial</option>
                      <option value='Hospitalar' >Hospitalar</option>
                      <option value='Completo (AMB + HOSP + OBST)' >Completo (AMB + HOSP + OBST)</option>
                      <option value='Completo (AMB + HOSP + OBST) + Odontológico' >Completo (AMB + HOSP + OBST) + Odontológico</option>
                      <option value='Hospitalar + Odontológico' >Hospitalar + Odontológico</option>
                      <option value='Ambulatorial + Odontológico' >Ambulatorial + Odontológico</option>
                      <option value='Hospitalar + Obstetrícia' >Hospitalar + Obstetrícia</option>
                      <option value='Ambulatorial + Hospitalar' >Ambulatorial + Hospitalar</option>
                      <option value='Urgência + Emergência' >Urgência + Emergência</option>
                  </select>
                 
              </div>
          </div>
      </Col>
      <Col lg={6} >
          <div>
              <div className="mb-3">
                  <Label htmlFor="example-text-input" className="form-Label">Acomodação</Label>
                  <select name="acomodacao" value={dados[2]} className="form-control"  onChange={(e)=>{
                    setFiltro3(e.target.value=='Selecione' || e.target.value=='Todos'?'':e.target.value)
                    let dadosNew = [...dados]
                    dadosNew[2] = e.target.value
                    setDados(dadosNew)
                  }} >
                      <option>Selecione</option>
                      <option value='Todos' >Todos</option>
                      <option value='Ambulatorial' >Ambulatorial</option>
                      <option value='Enfermaria' >Enfermaria</option>
                      <option value='Apartamento' >Apartamento</option>
                      <option value='Sem acomodação' >Sem acomodação</option>
                  </select>
                 
              </div>
          </div>
      </Col>
      {ramo=='2' &&
         <>
         <Col lg={6}>
         <div>
             <div className="mb-3">
                 <Label htmlFor="example-text-input" className="form-Label">Coparticipação</Label>
                 <select name="coparticipacao" value={dados[3]} className="form-control" onChange={(e)=>{
                   setFiltro4(e.target.value=='Selecione' || e.target.value =='Todos'?'':e.target.value)
                   let dadosNew = [...dados]
                   dadosNew[3] = e.target.value
                   setDados(dadosNew)
                 }} >
                     <option>Selecione</option>
                     <option value='Todos' >Todos</option>
                     <option value='Sim' >Sim</option>
                     <option value='Não' >Não</option>
                 </select>
                
             </div>
         </div>
     </Col>
     <Col lg={6}>
         <div>
             <div className="mb-3">
                 <Label htmlFor="example-text-input" className="form-Label">Contratação</Label>
                 <select name="contratacao"  value={dados[4]} className="form-control" onChange={(e)=>{
                       setFiltro5(e.target.value=='Selecione' || e.target.value=='Todos'?'':e.target.value)
                       let dadosNew = [...dados]
                       dadosNew[4] = e.target.value
                       setDados(dadosNew)
                 }} >
                     <option>Selecione</option>
                     <option value='Todos' >Todos</option>
                     <option value='OPCIONAL' >Opcional</option>
                     <option value='COMPULSÓRIO' >Compulsório</option>
                     <option value='AMBOS' >Ambos</option>
                 </select>
                
             </div>
         </div>
     </Col></>
      }
   
      <Col lg={6}>
          <div>
              <div className="mb-10"   >
                  <Label htmlFor="example-text-input" className="form-Label">Municipios</Label>
                  <select name="municipio" value={dados[5]} className="form-control" onChange={(e)=>{
                    setFiltro6(e.target.value=='Selecione'?'':e.target.value)
                    let dadosNew = [...dados]
                       dadosNew[5] = e.target.value
                       setDados(dadosNew)
                }} >
                    <option>Selecione</option>
                    {municipios && municipios.map((item)=>(
                        <option key={item.id} value={item.id} >{item.nome}</option>
                    ))
                    }
                </select>
              </div>
          </div>
      </Col>

    </Row>
       
        </div>
        <div className="modal-footer">
            <button className="btn btn-success" onClick={handleFiltro}  >Filtrar</button>
        </div>
      </Modal>
    )
}
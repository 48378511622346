import React, { useEffect, useState } from "react";
import { Api } from "../../api";
import { Login } from "../Corpo/Header/Login";
import { useCookies } from 'react-cookie';
export default function MainSection() {
  const [listOriginal, setListOriginal] = useState();
  const [list, setList] = useState();
  const [ultimas, setUltimas] = useState();
  const [escolha, setEscolha] = useState();
  const [categorias, setCategorias] = useState();
  const [cookie, setCookie] = useCookies(['authUser'])
  const obj =cookie.authUser
  
  const handleDados = async () => {
    let json = await Api.treinamentos();
    let list = json?.list
    setListOriginal(list)
    setList(list)
  };
  useEffect(() => {
    handleDados();
  }, []);

  const handleBuscar = async (buscar) => {
    let listagem = listOriginal
    listagem = listagem.filter((item) => (buscar ? item.nome.toUpperCase().includes(buscar.toUpperCase()) : true))
    setList(listagem)
  }
  const [modalLogin, setModalLogin] = useState(false);

  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <section className="blog-page-area bg-lighter">
      <div className="container">
        <div className="row" style={{ height: '100vh' }}>
          <div className="col-lg-12" style={{ backgroundColor: '#F6F7F9' }}>
            <div className="blog-standard-content pt-20 rpt-100 pb-110 ">
              {list &&
                list.map((item, index) => (
                  <div key={index} style={{  width: '100%', borderRadius: 10, borderBottom: '2px solid #E5EEF6' }}>
                    <div className="blog-header" style={{ top: 0 }}>
                      <ul className="post-meta-item mr-15">
                        <li>
                          <i className="fas fa-calendar-alt"></i>
                          <a href="#">{item?.data} - {item?.hora}</a>
                        </li>
                      </ul>
                    </div>
                    <div className="blog-standard-item wow fadeInUp delay-0-2s" style={{ display: 'flex', alignItems: 'flex-start', marginBottom: 10, flexDirection: isDesktop ? 'row' : 'column' }}  >
                    <div className="" style={{ flex: 1, display: 'flex', alignItems: 'center' ,marginBottom:20}}>
                        {item?.imagem && (
                          <img src={`https://server.corpar.com.br/imagem/${item?.imagem}`} width={500} />
                        )}
                       {(item?.video || item.link) && (
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 10 }} >
                            <iframe width="280" height="220" style={{ borderRadius: 10 }} src={link || `https://server.corpar.com.br/video/` + item.video} title="" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
                          </div>
                        )}
                      </div>
                      <div style={{ flex: 1, display: "flex", alignItems: 'flex-start', flexDirection: "column", justifyContent: "space-around", height: '100%' }}  >
                        <h3>{item?.nome}</h3>
                        <p>{item?.descricao}</p>
                        <p><button className="btn btn-primary" onClick={() => { 
                          if(obj?.id){
                            window.open(item?.url, "_blank")
                          }else{
                            setModalLogin(true)
                          }
                        }}  >Clique aqui para acessar</button></p>
                      </div>
                    
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </div>
      <Login modalLogin={modalLogin} setModalLogin={setModalLogin} />
    </section>
  );
}

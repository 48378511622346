import React, { useEffect, useState } from "react";
import { Col, Label, Modal, Row } from "reactstrap";
import Select from "react-select";
export const Filtros = ({ modalFiltro, setModalFiltro, handleCarregamento, municipios, ramo, calculo, setCalculoFiltro }) => {
    const traducaoCobertura = [{value:'Hospitalar',label:'Hospitalar'},
        {value:'Urgência + Emergência',label:'Urgência e Emergência'},
        {value:'Ambulatorial',label:'Ambulatorial'},
        {value:'Completo (AMB + HOSP + OBST)',label:'Amb + Hosp Com Obstetrícia'},
        {value:'Completo (AMB + HOSP + OBST) + Odontológico',label:'Amb + Hosp + Odonto Com Obstetrícia'},
        {value:'Hospitalar + Odontológico',label:'Hospitalar + Odontológico'},
        {value:'Ambulatorial + Odontológico',label:'Ambulatorial Com Odonto'},
        {value:'Hospitalar + Obstetrícia',label:'Hospitalar + Obstetrícia'},

        {value:'Ambulatorial + Hospitalar',label:'Amb + Hosp Sem Obstetrícia'},
        {value:'Amb + Hosp + Odonto sem Obstetrícia',label:'Amb + Hosp + Odonto sem Obstetrícia'}]
    const traducaoCoparticipacao = [{ value: 'Sim', label: 'Com Coparticipação' }, { value: 'Não', label: 'Sem Coparticipação' }, { value: 'Parcial', label: 'Com Coparticipação Parcial' }]
    const traducaoContratacao=  [ { value: 'OPCIONAL', label: 'Opcional' }, { value: 'COMPULSÓRIO', label: 'Compulsório' }]
    const traducaoReembolso = [{ value: 'Sim', label: 'Com Reembolso' }, { value: 'Não', label: 'Sem Reembolso' }]

    const [municipio, setMunicipio] = useState()
    const [cobertura, setCobertura] = useState()
    const [coparticipacao, setCoparticipacao] = useState()
    const [acomodacao, setAcomodacao] = useState()
    const [contratacao, setContratacao] = useState()
    const [reembolso, setReembolso] = useState()


    const [municipioList, setMunicipioList] = useState([])
    const [coberturaList, setCoberturaList] = useState([])
    const [coparticipacaoList, setCoparticipacaoList] = useState([])
    const [acomodacaoList, setAcomodacaoList] = useState([])
    const [contratacaoList, setContratacaoList] = useState([])
    const [reembolsoList, setReembolsoList] = useState([])

    const handleFiltro = () => {

        let filtro = [...calculo]
        let municipiodArray = []
        let coberturasarray = []
        let contratacaoarray = []
        let acomodacaoArray = []
        let coparticipacoesarray = []
        let reembolsoArray = []

        filtro = filtro.filter((fill) => fill.status == 1).filter((fill) =>
            (municipio?.value ? fill.tabelas.some((fill2) => fill2.areacomercializacaoestadosmunicipios.some((item) => item.id == municipio?.value)) : true) &&
            (cobertura?.value ? fill.tabelas.some((fill2) => fill2.cobertura == cobertura?.value) : true) &&
            (coparticipacao?.value ? fill.tabelas.some((fill2) =>( (['Sim','Total'].includes(fill2.coparticipacao)?"Sim":fill2.coparticipacao  ) == coparticipacao?.value)) : true) &&
            (acomodacao?.value ? fill.tabelas.some((fill2) => fill2.acomodacao == acomodacao?.value) : true) &&
            (contratacao?.value ? fill.tabelas.some((fill2) => fill2.contratacao == contratacao?.value || (fill2.contratacao == (contratacao?.value == 'AMBOS' ? 'Todos' : contratacao?.value))) : true) &&
            (reembolso?.value ? fill.tabelas.some((fill2) => fill2.possuereembolso == reembolso?.value) : true)
        )


        calculo.filter((fill) => fill.status == 1).map((fill) => {

            fill.tabelas.map((item) => {
                
                item.areacomercializacaoestadosmunicipios?.map((item2)=>{
                    if (!municipiodArray.some((item3)=>item3.id == item2?.id)) {
                        municipiodArray.push({id:item2?.id,nome:item2?.nome})
                    }
                  })

                if (item.possuereembolso && !reembolsoArray.includes(item.possuereembolso)) {
                    reembolsoArray.push(item.possuereembolso)
                }

                if (item.acomodacao && !acomodacaoArray.includes(item.acomodacao)) {
                    acomodacaoArray.push(item.acomodacao)
                }
                
                
                if (item.cobertura && !coberturasarray.includes(item.cobertura)) {
                    coberturasarray.push(item.cobertura)
                }

                if (item.coparticipacao && !coparticipacoesarray.includes(item.coparticipacao)) {
                    if ((item.coparticipacao == 'Sim' || item.coparticipacao == 'Total')) {


                        if (!coparticipacoesarray.some((item) => (item == 'Sim' || item == 'Total'))) {
                            coparticipacoesarray.push(item.coparticipacao)
                        }

                    } else {
                        coparticipacoesarray.push(item.coparticipacao)
                    }
                }

               
                if (item.contratacao && !contratacaoarray.includes(item.contratacao)) {

                    contratacaoarray.push(item.contratacao)
                }
            })



              //  (municipio?.value ? fill.tabelas.some((fill2) => fill2.areacomercializacaoestadosmunicipios.some((item) => item.id == municipio?.value)) : true)
        })


        setCalculoFiltro(filtro)
        setMunicipioList(municipiodArray)
        setCoberturaList(coberturasarray)
        setCoparticipacaoList(coparticipacoesarray)
        setAcomodacaoList(acomodacaoArray)
        setContratacaoList(contratacaoarray)
        setReembolsoList(reembolsoArray)

    }
    useEffect(() => {

        handleFiltro()
    }, [municipio, cobertura, coparticipacao, acomodacao, contratacao, reembolso])
    return (
        <div  >

            <div className="modal-body" style={{ border: 0 }}>
                <Row style={{ paddingRight: 15, paddingLeft: 15 }}>
                    <Col xs={6} lg={6}>
                        <div>
                            <div className="mb-10"   >


                                <Select name="municipio" value={municipio}
                                    onChange={(e) => { setMunicipio(e) }}
                                    menuPlacement={"bottom"}
                                    placeholder="Municípios"

                                    options={municipioList.sort((a, b) => {
                                        return a.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                                          .localeCompare(b.nome.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
                                      })?.map((item) => { return { value: item.id, label: item.nome } })} />

                            </div>
                        </div>
                    </Col>
                    <Col xs={6} lg={6}>
                        <div>
                            <div className="mb-3">
                            

                                <Select name='cobertura' value={cobertura}
                                    menuPlacement={"bottom"}
                                    onChange={(e) => { setCobertura(e) }}
                                    placeholder="Cobertura"
                                    options={[
                                        { value: '', label: 'Todos' },
                                        ...(coberturaList ? coberturaList.sort((a, b) => {
                                            return a.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                                              .localeCompare(b.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
                                          }).map((item) => {
                                          const option = traducaoCobertura?.find((fd) => fd.value === item);
                                          return option || null; // Verifica se a tradução existe, caso contrário, retorna null
                                        }) : []) // Se contratacaoList for undefined, retorna array vazio
                                      ].filter(Boolean)}

                                   />
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} lg={6}>
                        <div>
                            <div className="mb-3">


                                <Select name="coparticipacao" value={coparticipacao}
                                    onChange={(e) => { setCoparticipacao(e) }}
                                    menuPlacement={"bottom"}
                                    placeholder="Coparticipacao"
                                    options={[
                                        { value: '', label: 'Todos' },
                                        ...(coparticipacaoList ? coparticipacaoList.sort((a, b) => {
                                            return a.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                                              .localeCompare(b.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
                                          }).map((item) => {
                                          const option = traducaoCoparticipacao?.find((fd) => fd.value === item);
                                          return option || null; // Verifica se a tradução existe, caso contrário, retorna null
                                        }) : []) // Se contratacaoList for undefined, retorna array vazio
                                      ].filter(Boolean)}
                                     />


                            </div>
                        </div>
                    </Col>

                    <Col xs={6} lg={6} >
                        <div>
                            <div className="mb-3">
                                <Select name="acomodacao" value={acomodacao}
                                    onChange={(e) => { setAcomodacao(e) }}
                                    menuPlacement={"bottom"}
                                    placeholder="Acomodação"
                                    options={[
                                        { value: '', label: 'Todos' },
                                        ...(acomodacaoList ? acomodacaoList.sort((a, b) => {
                                            return a.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                                              .localeCompare(b.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
                                          }).map((item) => {
                                          const option = {label:item,value:item}
                                          return option || null; // Verifica se a tradução existe, caso contrário, retorna null
                                        }) : []) // Se contratacaoList for undefined, retorna array vazio
                                      ].filter(Boolean)}

                                  
                                    
                                    
                                    />


                            </div>
                        </div>
                    </Col>
                    <Col xs={6} lg={6}>
                        <div>
                            <div className="mb-3">

                       
                            
                                <Select name="contratacao" value={contratacao}
                                    onChange={(e) => { setContratacao(e) }}
                                    menuPlacement={"bottom"}
                                    placeholder="Contratação"
                                    options={[
                                        { value: '', label: 'Todos' },
                                        ...(contratacaoList ? contratacaoList.sort((a, b) => {
                                            return b.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                                              .localeCompare(a.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
                                          }).map((item) => {
                                          const option = traducaoContratacao?.find((fd) => fd.value === item);
                                          return option || null; // Verifica se a tradução existe, caso contrário, retorna null
                                        }) : []) // Se contratacaoList for undefined, retorna array vazio
                                      ].filter(Boolean)}/>


                            </div>
                        </div>
                    </Col>
                    <Col xs={6} lg={6} >
                        <div>
                            <div className="mb-3">

                                <Select name="possueReembolso" value={reembolso}
                                    onChange={(e) => { setReembolso(e) }}
                                    menuPlacement={"bottom"}
                                    placeholder="Reembolso"
                                    options={[
                                        { value: '', label: 'Todos' },
                                        ...(reembolsoList ? reembolsoList.sort((a, b) => {
                                            return b.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
                                              .localeCompare(a.normalize('NFD').replace(/[\u0300-\u036f]/g, ''));
                                          }).map((item) => {
                                          const option = traducaoReembolso?.find((fd) => fd.value === item);
                                          return option || null; // Verifica se a tradução existe, caso contrário, retorna null
                                        }) : []) // Se contratacaoList for undefined, retorna array vazio
                                      ].filter(Boolean)}
 />
                            </div>
                        </div>
                    </Col>




                </Row>

            </div>
            {/* <div className="modal-footer" style={{ border: 0 }}>
                <button className="btn btn-primary" onClick={handleFiltro}  >Filtrar</button>
            </div> */}
        </div>



    )
}
import React, { useEffect, useState } from "react";
import { FaAngleUp } from "react-icons/fa";
import { FaAngleDown } from "react-icons/fa6";
import { useNavigate } from 'react-router-dom';
import { useCookies } from "react-cookie";

export const ItensMenu = ({ title, imagem, link, privado = false, html = '', onClick, subMenu, id, itemMenu, setItem, setSize, itemSize, obj, action, setModalLogin, icone }) => {
    const navigate = useNavigate();
    const [cookies, setCookie] = useCookies();

    const handler = (e, value) => {
        // e.preventDefault();
        let getItems = document.querySelectorAll(`#${value} li`).length;
        if (getItems > 0) {
            getItems = getItems / 2
            if (itemMenu !== value) {
                setSize(`${getItems * 40}px`);
                setItem(value);
            } else {
                setItem("");
            }
        }
    };
    const handleLogin = (e, privado, link) => {
        if (privado ? !obj?.id : false) {
            setCookie("authPagehist", '/ferramentas', { path: "/", maxAge: 365 * 24 * 60 * 60, sameSite: "strict", secure: true });
            // localStorage.setItem("authPagehist", '/ferramentas');
            setModalLogin(true)
        } else {
            action(e)
            setItem("");
            navigate(link)
        }
    }
    const handleClick = (e, privado, link) => {
        if (onClick) {
            onClick()
        } else if ((!subMenu || subMenu?.length == 0)) {
            handleLogin(e, privado, link)
        } else {
            handler(e, id)
        }
    }
    return (
        <li id={id} className="menu-item-has-children active"  >
            <a style={{ marginBottom: 2, border: 'none', fontWeight: 100, color: '#535353' }} onClick={(e) => {
                handleClick(e, privado, link)

            }}  >
                {html}
                {imagem &&
                    <img src={imagem} style={{ width: 18, height: 18, marginRight: 6, marginBottom: 4 }} />
                }
                {icone &&
                    icone
                }
                {title}
                {subMenu?.length && (
                    <span className="menu-expand" >
                        {itemMenu == id ? <FaAngleUp color="#535353" /> : <FaAngleDown color="#535353" />}
                    </span>)
                }
            </a>

            {subMenu?.length > 0 && (
                <ul className="sub-menu" style={{ height: itemMenu == id ? itemSize : "0px" }} >
                    {subMenu?.map((item, index) => (
                        <li className="menu-item-has-children active" key={id + '-' + index}>
                            <a onClick={(e) => { item.onClick ? item.onClick(true) : handleLogin(e, item.privado, item.link) }} style={{ border: 'none', marginBottom: 2, fontWeight: 100, color: '#535353' }}>
                                {item?.imagem &&
                                    <img src={item.imagem} style={{ width: 18, height: 18, marginRight: 6 }} />
                                }
                                {item?.icone &&
                                    item.icone
                                }
                                {item.title}
                            </a>
                        </li>
                    ))}
                </ul>
            )}

        </li>
    )
}
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Api } from "../../../api";
import { Login } from "../../Corpo/Header/Login";
import { useCookies } from 'react-cookie';

export default function TestimonialSection({ tipos,identificacao }) {

  const [modalLogin, setModalLogin] = useState(false);
  const [cookie,setCookie] = useCookies(['authUser'])
  const obj =cookie.authUser
  
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section className={`testimonial-section-three `} style={{ marginTop: 10 }}>
      <div className="">
        <div className="testimonial-three-wrap " style={{ width: '100%' }}>
          <div className="row align-items-center" style={{ width: '100%' }}>

            <div className="" style={{ width: '100%' }}>
              <div className="testimonial-three-content text-center wow fadeInLeft delay-0-2s">

                
                <div className="row">
                <button className="col-12 feature-item  "
                  style={{ marginBottom: 10, border: 'none', paddingRight: 30, paddingLeft: 30, borderBottom: '2px solid #e6ecf7', color: '#454545', borderRadius: 0, padding: 5 }} >

                </button>
                  {tipos && tipos.map((item, index) => (
                    <button  key={index}
                      className="col-12 feature-item style-three"
                      style={{ marginBottom:index==(tipos.length -1)?0: 15, padding: 5, border: 'none', paddingLeft: 30, borderBottom:index==(tipos.length -1)? 'none':'2px solid #e6ecf7', color: '#454545', display: 'flex' }}  >
                      <span className="menu-expand" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', lineHeight: 1.4, width: '100%', display: 'flex', alignItems: 'center' }}>
                        <img className="w-10"
                          style={{ width: (identificacao=='fornecedores'? "50px":'20px'),marginRight:identificacao=='fornecedores'?0:5 }}
                          src={`https://server.corpar.com.br/imagens/${item.image || item.icone}`}
                          alt="Testimonial"
                        />
                        {item?.nome ? item.nome : item.title}{}
                      </span>
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Login modalLogin={modalLogin} setModalLogin={setModalLogin} />
    </section>
  );
}

import axios from "axios";
import { baseUrlApi } from './baseUrl'
const axiosInstance = axios.create({
  baseURL: `${baseUrlApi}/site`,
  headers: {
    "Content-Type": "application/json"
  }
});
export const Api = {
  dominios:async(dados)=>{
    let response = await axiosInstance.post("/dominios",dados);
    return response.data;
  },
  ramooperadorasDivulgacao:async()=>{
    let response = await axiosInstance.post("/RamooperadorasDivulgacao");
    return response.data;
  },
  estrutura: async () => {
    let response = await axiosInstance.get("/estrutura");
    return response.data;
  },
  Visualizacao: async (dados) => {
    let response = await axiosInstance.post("/blogView",dados);
    return response.data;
  },
  VisualizacaoMaterial: async (dados) => {
    let response = await axiosInstance.post("/materialView",dados);
    return response.data;
  },
  banner: async (dados) => {
    let response = await axiosInstance.post("/banner", dados);
    return response.data;
  },
  areas: async () => {
    let response = await axiosInstance.post("/areas", { dominio: window.location.host });
    return response.data;
  },
  operadoras: async (id) => {
    let response = await axiosInstance.get(`/operadoras/${id}`);
    return response.data;
  },
  operadora: async (id) => {
    let response = await axiosInstance.get(`/operadora/${id}`);
    return response.data;
  },
  ramosAtuacao: async (dados) => {
    let response = await axiosInstance.post("/ramosAtuacao",dados);
    return response.data;
  },
  ramosPaiAtuacao: async (dados) => {
    let response = await axiosInstance.post("/ramosPaiAtuacao",dados);
    return response.data;
  },
  
  sobre: async (dados) => {
    let response = await axiosInstance.post("/sobre",dados);
    return response.data;
  },
  depoimentos: async () => {
    let response = await axiosInstance.post("/depoimentos", { dominio: window.location.host });
    return response.data;
  },
  blogs: async () => {
    let response = await axiosInstance.post("/blog", { dominio: window.location.host });
    return response.data;
  },
  materiais: async () => {
    let response = await axiosInstance.post("/materiais", { dominio: window.location.host });
    return response.data;
  },
  treinamentos: async () => {
    let response = await axiosInstance.post("/treinamentos", { dominio: window.location.host });
    return response.data;
  },
  estadosSaude: async (dados) => {
    let response = await axiosInstance.get(`/estadossaude/${dados}`);
    return response.data;
  },
  estadosOdontologico: async () => {
    let response = await axiosInstance.get("/estadosodontologico");
    return response.data;
  },
  operadoraTipo: async (dados) => {
    let response = await axiosInstance.post("/operadoraTipo", dados);
    return response.data;
  },
  operadoraSaude: async (dados) => {
    let response = await axiosInstance.post("/operadoraSaude", dados);
    return response.data;
  },
  operadoraOdontologico: async (dados) => {
    let response = await axiosInstance.post("/operadoraOdonto", dados);
    return response.data;
  },
  operadoraPet: async (dados) => {
    let response = await axiosInstance.post("/operadorapet", dados);
    return response.data;
  },
  operadoraRH: async (dados) => {
    let response = await axiosInstance.post("/operadoraTipo", dados);
    return response.data;
  },
  operadoraUTI: async (dados) => {
    let response = await axiosInstance.post("/operadoraUTI", dados);
    return response.data;
  },
  operadoraCARD: async (dados) => {
    let response = await axiosInstance.post("/operadoraCARD", dados);
    return response.data;
  },
  operadoraConsorcio:async (dados) => {
    let response = await axiosInstance.post("/operadoraconsorcio", dados);
    return response.data;
  },
  operadoraMaterial: async (dados) => {
    let response = await axiosInstance.post("/operadoramaterial", dados);
    return response.data;
  },
  operadoraSaudeVenda: async (dados) => {
    let response = await axiosInstance.post("/operadorasaudevenda", dados);
    return response.data;
  },
  operadoraOdontologicoVenda: async (dados) => {
    let response = await axiosInstance.post("/operadoraodontologicovenda", dados);
    return response.data;
  },
  operadoraSeguro: async (dados) => {
    let response = await axiosInstance.post("/operadoraSeguro", dados);
    return response.data;
  },
  materialDivulgacao: async (dados) => {
    let response = await axiosInstance.post("/materialDivulgacao", dados);
    return response.data;
  },
  materialDivulgacaoEstados: async (dados) => {
    let response = await axiosInstance.post("/materialDivulgacaoEstados", dados);
    return response.data;
  },
  loginSocialGoogle: async (dados) => {
    let response = await axiosInstance.post("/loginSocialGoogle", dados);
    if (response.data.error == false) {
      cookies.set("authUser", JSON.stringify(response.data), { path: "/",  secure: true, sameSite: "Strict"});
      // localStorage.setItem("authUser", JSON.stringify(response.data));
      window.location.href = '/ferramentas'
    }
    return response.data;
  },
  operadorasDivulgacao: async () => {
    let response = await axiosInstance.get("/operadorasDivulgacao/listagem");
    return response.data;
  },
  DadosBuscarCNPJ: async (cnpj) => {
    let response = await axiosInstance.post(`/buscarCNPJ`,{cnpj});
    return response.data;
  },
  gestorModal: async (tela) => {
    let response = await axiosInstance.post(`/gestorModal`,{tela});
    return response.data;
  },
  operadorasAll:async (ramo) =>{
    let response = await axiosInstance.get(`/operadorasAll/${ramo}`);
    return response.data;
  },
  ramosFornecedores:async (dados) =>{
    let response = await axiosInstance.post(`/ramosFornecedores`,dados);
    return response.data;
  },
  ramosFornecedor:async () =>{
    let response = await axiosInstance.post(`/ramosFornecedor`);
    return response.data;
  },
  fornecedorRamos:async () =>{
    let response = await axiosInstance.post(`/fornecedorRamos`);
    return response.data;
  },
  RamosFornecedoresTemplate:async (dados) =>{
    let response = await axiosInstance.post(`/ramosFornecedoresTemplate`,dados);
    return response.data;
  },
  RamosTemplate:async (dados) =>{
    let response = await axiosInstance.post(`/ramosTemplate`,dados);
    return response.data;
  },
  ramosVendaOline:async()=>{
    let response = await axiosInstance.get(`/ramosVendaOline`);
    return response.data;
  }

  
};

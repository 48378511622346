import React, { useEffect, useRef, useState } from "react";
import { HiOutlineX } from "react-icons/hi";

export const ExitModal = ({setModal,top}) => {  
    return (
        <div style={{ top:top?top: 10, right: 20, zIndex: 9999, position: "absolute", display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%',width:20,height:20 }} >
            <button type="button" onClick={(e) => { setModal(false); }} className="close" data-dismiss="modal" aria-label="Close" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                <HiOutlineX size={20} color={'#5c5c5c'} />
            </button>
        </div>
    )
}
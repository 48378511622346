import React from "react";
import Dados from "./index";
import Layouts from "../Corpo/Layouts";

export default function ferramentasArea() {
  return (
    <>
      <Layouts pageTitle="Tabelas Personalizadas" breadcrumbs={[
      { name: "Home", path: "../" },
      {name:'Ferramentas de Vendas',path:'/ferramentas'},
      { name: "Tabelas Personalizadas", path: "/tabela" },
    ]}>
        <Dados />
      </Layouts>
    </>
  );
}

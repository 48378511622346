import React, { useState } from "react";
import { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Cotacoes from "./pages/Cotacoes/header";
import TabelaPersonalizada from "./pages/TabelasPersonalizadas/header";
import Home from "./pages/Corpo"
import { Login } from "./components/HomeThree/login";
import Material from "./pages/Material/header";
import Plano from "./pages/Plano/header";
import TabelaPrecos from "./pages/TabelaPrecos/header";
import FerramentaVendas from "./pages/FerramentaVendas/header";
import Ramos from "./pages/Ramos/header";
import RamosList from "./pages/RamosList/header";
import VendaOnline from "./pages/VendaOnline/header";
//Rota com problema
import Blog from "./pages/Blog/header";
import Treinamentos from "./pages/Treinamentos/header";
import Operadoras from "./pages/Operadoras/header";
import PageCotacoes from "./pages/PageCotacoes";
import PageTemplate from "./pages/PageTemplate/header";
import PageTemplateRamo from "./pages/PageTemplateRamo/header";

import FourZeroFour from "./components/FourZeroFour";
import CotacoesPrecos from "./pages/CotacoesPrecos/header";
import TermosDeUso from "./pages/termos/header";
import PoliticaPrivacidade from "./pages/termosPoliticas/header";
import { useCookies } from "react-cookie";

export default function Routers() {
  const [cookies] = useCookies(['authUser','authPagehist','authToken']);

  const [obj, setObjs] = useState()
  const [valida, setValida] = useState(true)
  useEffect(() => {
    const getAuthUser =cookies.authUser;
 
    if(!getAuthUser?.id){
      setValida(false)
    }
    setObjs(cookies.authUser)
  }, []);
  return (
    <Routes>

      <Route exact path="/" element={<Home />} />
      <Route exact path="/login/:tokenUrl" element={<Home />} />



      <Route exact path="/plano/:plano" element={<Plano />} />

      {/* <Route exact path="/:estado/:plano/:pessoa/:operadora"  element={<Fornecedor /> } /> */}
      <Route exact path="/ramos" element={<RamosList />} />
      <Route exact path="/ramos/:id" element={<PageTemplateRamo />} />



      <Route exact path="/produtos" element={valida ? <Ramos /> : <FourZeroFour />} />
      {/* <Route exact path="/ramos/:id" element={valida ? <Ramos /> : <FourZeroFour />} /> */}

      {/* <Route exact path="/operadora/:ramo" element={<Operadoras />} /> */}
      <Route exact path="/empresas" element={<Operadoras />} />
      <Route exact path="/empresas/:id" element={<PageTemplate />} />

 


      <Route exact path="/comunicados" element={<Blog />} />
      <Route exact path="/treinamentos" element={<Treinamentos />} />
      <Route exact path="/404" element={<FourZeroFour />} />
      {/* Rotas Privadas */}
      <Route exact path="/tabelaprecos/:id" element={valida ? <TabelaPrecos /> : <FourZeroFour />} />
      <Route exact path="/tabelacotacoes/:id/:idOperadora" element={valida ? <PageCotacoes /> : <FourZeroFour />} />
      <Route exact path="/tabelacotacoes/:id/" element={valida ? <PageCotacoes /> : <FourZeroFour />} />
      <Route exact path="/ferramentas" element={valida ? <FerramentaVendas /> : <FourZeroFour />} />
      {/* <Route exact path="/cotacoes" element={valida ? <Cotacoes /> : <FourZeroFour />} /> */}
      <Route exact path="/cotacoesprecos/:id" element={valida ?  <CotacoesPrecos/>: <FourZeroFour /> } />
      <Route exact path="/cotacoes" element={valida ? <Cotacoes /> : <FourZeroFour />} />
      <Route exact path="/cotacoes/minhascotacoes" element={valida ? <Cotacoes /> : <FourZeroFour />} />
      <Route exact path="/tabela" element={valida ? <TabelaPersonalizada /> : <FourZeroFour />} />
      <Route exact path="/vendaOnline/:id" element={valida ? <VendaOnline /> : <FourZeroFour />} />
      <Route exact path="/vendaOnline" element={valida ? <VendaOnline /> : <FourZeroFour />} />
      <Route exact path="/materiais" element={valida ? <Material /> : <FourZeroFour />} />
      <Route exact path="/materiais/:id" element={valida ? <Material /> : <FourZeroFour />} />
      <Route exact path="/unauthorized" element={ <FourZeroFour />} />
      <Route exact path="/termodeuso" element={ <TermosDeUso />} />
      <Route exact path="/politicadeprivacidade" element={ <PoliticaPrivacidade />} />

      <Route exact path="/:id" element={<PageTemplate />} />

    </Routes>
  );
}

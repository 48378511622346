import html2pdf from 'html2pdf.js';
import React, { useEffect, useRef, useState } from "react";
import ReactToPrint from "react-to-print";
import { Col, Modal, Row } from "reactstrap";
import baixar from "../../../assets/images/icones/baixar.png";
import mail from "../../../assets/images/icones/mail.png";
import wpp from "../../../assets/images/icones/whatsapp.png";
import logo from "../../../assets/images/logos/logo3.png";
import { Api } from '../../../api/apiTabelaPrecos'
import { MdOutlineEmail as Email } from "react-icons/md";
import { IoLogoWhatsapp as Wpp } from "react-icons/io5";
import { LuPrinter as Imprimir } from "react-icons/lu";
import { RiDownloadCloudLine as Baixar } from "react-icons/ri";
import { IoMdPrint } from "react-icons/io";
import logoAqui from "../../../assets/images/sualogoaqui.png";
import Icone from "../../../assets/icones/icone.png"
import { baseUrlApi } from "../../../api/baseUrl";
import { FaTableList } from "react-icons/fa6";
import { TbFilterDown } from "react-icons/tb";
import { TbFilterUp } from "react-icons/tb";
import { LiaEyeSolid } from "react-icons/lia";
import { LiaEyeSlash } from "react-icons/lia";
import { IoIosClose } from "react-icons/io";
import { FaRegUser } from "react-icons/fa";
import { FaWhatsapp } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { SlCalculator } from "react-icons/sl";
import { IoLocationOutline } from "react-icons/io5";
import { MdOutlineDateRange } from "react-icons/md";
import { ExitModal } from '../../components/exitModal';
import { useCookies } from 'react-cookie';

function VisualizarTabela({ info, nomeProduto }) {
  const [cookie, setCookie] = useCookies(['authUser'])
  const obj = cookie.authUser
  const divRef = useRef(null);
  const [modalMunicipios, setModalMunicipios] = useState(false)
  const [municipios, setMunicipios] = useState([])
  const handleMunicipios = (municipio) => {
    setMunicipios(municipio)
    setModalMunicipios(true)
  }
  const gerarPDF = () => {

    const divElement = divRef.current;
    if (divElement) {
      const opt = {
        margin: 10,
        filename: 'documento.pdf',
        image: { type: 'jpeg', quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      };

      html2pdf().from(divElement).set(opt).outputPdf().then(pdf => {
        const pdfBlob = new Blob([pdf], { type: 'application/pdf' });
        const pdfUrl = URL.createObjectURL(pdfBlob);

        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = 'documento.pdf';
        link.click();

        URL.revokeObjectURL(pdfUrl);
      });
    }
  };
  const [visualizar, setVisualizar] = useState(false)
  const [tabela, setTabela] = useState([])
  const dataAtual = () => {
    const meses = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril',
      'Maio', 'Junho', 'Julho', 'Agosto',
      'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];
    const data = new Date();
    const mes1 = meses[data.getMonth()];
    const dia = data.getDate();
    const mes = data.getMonth() + 1; // o valor do mês começa em 0, então é necessário adicionar 1
    const ano = data.getFullYear();
    return { data1: `${dia < 10 ? '0' + dia : dia}/${mes < 10 ? '0' + mes : mes}/${ano}`, data2: `${mes1}/${ano}` }
  }
  

  const organizar = () => {
    setVisualizar(false)
    setTabela([])

    let produtos = info?.nome
    let coberturas = info?.cobertura
    let acomodacaos = info?.acomodacao
    let coparticipacaos = info?.coparticipacao
    let precoVidas = info?.precovida
    let faixas2 = info?.faixa
    let tabelas = [{ nome: [], cobertura: [], acomodacao: [], coparticipacao: [], faixas: [], precoVida: [] }]
    let cont = 0
    let contItem = 0
    let arrayFaixa = []
    if (produtos?.length > 0) {
      for (let index = 0; index <= produtos.length; index++) {
        cont = cont + 1
        let nome = produtos ? produtos[index] ? produtos[index] : '' : ''
        let cobertura = coberturas ? coberturas[index] ? coberturas[index] : '' : ''
        let acomodacao = acomodacaos ? acomodacaos[index] ? acomodacaos[index] : '' : ''
        let coparticipacao = coparticipacaos ? coparticipacaos[index] ? coparticipacaos[index] : '' : ''
        let faixa = faixas2 ? faixas2[index] ? faixas2[index] : [] : []
        let precoVida = precoVidas ? precoVidas[index] ? precoVidas[index] : '' : ''

        if (cont <= 5) {
          if (!(nome == '' && cobertura == '' && acomodacao == '' && coparticipacao == '' && precoVida == '')) {
            tabelas[contItem].nome.push(nome)
            tabelas[contItem].cobertura.push(cobertura)
            tabelas[contItem].acomodacao.push(acomodacao)
            tabelas[contItem].coparticipacao.push(coparticipacao)
            tabelas[contItem].precoVida.push(precoVida)
            arrayFaixa.push(faixa)
          }
        } else {
          tabelas[contItem].nome.push(nome)
          tabelas[contItem].cobertura.push(cobertura)
          tabelas[contItem].acomodacao.push(acomodacao)
          tabelas[contItem].coparticipacao.push(coparticipacao)
          tabelas[contItem].precoVida.push(precoVida)
          arrayFaixa.push(faixa)

          let juntar = juntarNomesIguais(arrayFaixa)
          tabelas[contItem].faixas.push(juntar)
          arrayFaixa = []
          contItem = contItem + 1
          tabelas.push({ nome: [], cobertura: [], acomodacao: [], coparticipacao: [], faixas: [], precoVida: [] })
          cont = 0
        }
        if (index == produtos.length) {
          let juntar = juntarNomesIguais(arrayFaixa)
          tabelas[contItem].faixas.push(juntar)
          arrayFaixa = []
          contItem = contItem + 1


        }
      }
    }

    let tabelasOrdernadas = ordenarDados(tabelas)

    setTabela(tabelas)
    setVisualizar(true)
  }
  useEffect(() => {
    organizar()
  }, [info])
  const ordenarDados = (tabelas) => {
    // Cria um array para armazenar todos os dados com a faixa 0-18
    let dadosArray = [];

    tabelas.forEach(tabela => {
      if (tabela && tabela.faixas && tabela.faixas[0]) {
        // Encontra a faixa 0-18
        const faixa018 = tabela.faixas[0].find(faixa => faixa.id === 0);

        if (faixa018 && faixa018.valor) {
          faixa018.valor.forEach((valor, index) => {
            if (tabela.nome[index] !== undefined) {
              dadosArray.push({
                faixaId: faixa018.id,
                faixaNome: faixa018.nome,
                valor: valor,
                nome: tabela.nome[index],
                cobertura: tabela.cobertura[index] || '',
                acomodacao: tabela.acomodacao[index] || '',
                coparticipacao: tabela.coparticipacao[index] || '',
                precoVida: tabela.precoVida[index] || ''
              });
            }
          });
        }
      }
    });

    // Ordena o array de objetos baseado nos valores da faixa 0-18
    dadosArray.sort((a, b) => a.valor - b.valor);

    // Reorganiza os dados nas propriedades de acordo com a nova ordem
    const sortedData = {
      nome: new Array(dadosArray.length),
      cobertura: new Array(dadosArray.length),
      acomodacao: new Array(dadosArray.length),
      coparticipacao: new Array(dadosArray.length),
      faixas: new Array(dadosArray.length),
      precoVida: new Array(dadosArray.length),
      valores: new Array(dadosArray.length)
    };

    dadosArray.forEach((item, index) => {
      sortedData.nome[index] = item.nome;
      sortedData.cobertura[index] = item.cobertura;
      sortedData.acomodacao[index] = item.acomodacao;
      sortedData.coparticipacao[index] = item.coparticipacao;
      sortedData.faixas[index] = item.faixaNome;
      sortedData.precoVida[index] = item.precoVida;
      sortedData.valores[index] = item.valor;
    });

    return sortedData;
  };

  function juntarNomesIguais(array) {
    const resultado = [];
    const mapNomes = new Map();

    array.forEach((subarray) => {
      subarray.forEach((item) => {
        const nome = item.nome;
        if (!mapNomes.has(nome)) {
          mapNomes.set(nome, { id: item.id, status: item.status, nome, valor: item.valor });
        } else {
          const objExistente = mapNomes.get(nome);
          objExistente.valor.push(...item.valor);
        }
      });
    });

    mapNomes.forEach((obj) => {
      // obj?.valor.sort((a, b) => a - b)

      resultado.push(obj);
    });
    resultado.sort((a, b) => a.id - b.id);

    return resultado;
  }
  const Compartilha = async () => {
    let response = await Api.compartilhar({ tabela: info.id })
    alert(response?.msg)
  }
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [info]);
  function aplicarMascaraCelular(numero) {
    // Remove qualquer caractere que não seja um dígito
    numero = numero?.replace(/\D/g, '');

    // Aplica a máscara de celular
    if (numero?.length === 11) {
      return numero.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
    } else {
      return numero; // Retorna o número sem formatação se não tiver 11 dígitos
    }
  }
  useEffect(() => {
   // console.log({ info, tabela })
  }, [info, tabela])
  return (
    <section className="featured-section bgs-cover pt-20  pb-20 rpb-100" style={{ minHeight: 850 }}>
      <div className="" style={{ paddingRight: isDesktop ? 150 : 0, paddingLeft: isDesktop ? 150 : 0 }} >


        {(tabela[0] && tabela[0]?.nome?.length == 0) && (
          <div className={`col-lg-12 col-sm-12 item `} style={{ marginTop: "10px", minHeight: 299, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <img src={Icone} className="rotating-image" />
          </div>
        )}
        {(tabela[0] && tabela[0]?.nome?.length > 0) && <>
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

            <ReactToPrint
              trigger={() => <button className="btn btn-default" style={{ float: 'right', marginTop: 30 }} >
                <IoMdPrint size={30} color={'#5c5c5c'} />
              </button>}
              content={() => divRef.current} />
            <a style={{ float: 'right', marginTop: 30 }} className="btn btn-default" onClick={() => { Compartilha() }} rel="noreferrer" target="_blank">
              <Email size={30} color={'#5c5c5c'} />
            </a>
            <a style={{ float: 'right', marginTop: 30 }} className="btn btn-default" href={`https://api.whatsapp.com/send?text=Olá,%20essa%20é%20a%20tabela%20personalizada%20que%20geramos%20para%20você,%20acesse%20esse%20link%20para%20visualizar%20:%20https%3A%2F%2F${window.location.href.replace('https://', '').replace('http://', '')}`} rel="noreferrer" target="_blank">
              <Wpp size={30} color={'#5c5c5c'} />

            </a>

          </div>

          <div ref={divRef} style={{padding:25}} >

            <div style={{ display: 'flex', justifyContent: "space-between", flexDirection: isDesktop ? 'row' : 'column', padding: 10 ,marginBottom:25}}>

              {obj?.foto ?
                <div style={{ flex: 2, flexWrap: 'wrap', }}>
                  <img src={`${baseUrlApi}/imagens/${obj?.foto}`} alt="" height="100%" style={{ paddingTop: 10 }} />
                </div> :
                <div style={{ flex: 2, flexWrap: 'wrap', }}>
                  <img src={logoAqui} alt="" height="70%" style={{ paddingTop: 10 }} />
                </div>
              }

              <div style={{ flex: 2, flexWrap: 'wrap' }}>
                <p style={{ marginBottom: 2, fontSize: 14, color: '#092140', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><FaRegUser size={12} style={{ marginRight: 5 }} /> Corretor:{obj?.nome}</p>
                <p style={{ marginBottom: 2, fontSize: 14, color: '#092140', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><FaWhatsapp size={12} style={{ marginRight: 5 }} />Celular: {aplicarMascaraCelular(obj?.dados.celular)}</p>
                <p style={{ marginBottom: 2, fontSize: 14, color: '#092140', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><MdOutlineEmail size={12} style={{ marginRight: 5 }} />Email:{obj?.email}</p>
              </div>
              <div style={{ flex: 1, flexWrap: 'wrap' }}>
                <p style={{ marginBottom: 2, fontSize: 14, color: '#092140', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><FaTableList size={12} style={{ marginRight: 5 }} /> Tabela Nº: {info?.id}</p>
                <p style={{ marginBottom: 2, fontSize: 14, color: '#092140', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><IoLocationOutline size={12} style={{ marginRight: 5 }} />Estado: {info?.estado} </p>
                <p style={{ marginBottom: 2, fontSize: 14, color: '#092140', display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}><MdOutlineDateRange size={12} style={{ marginRight: 5 }} />Gerado em {dataAtual().data1}</p>

              </div>

            </div>






            <div style={{ fontWeight: 'bold', fontSize: 14, height: 30, display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: 30}} >  <img src={`https://server.corpar.com.br/imagens/${info.imagens[0]?.imagem}`} width='200px' />   </div>


            {tabela && tabela.map((item, index) => (
              <div key={index+'-table'} style={{breakInside: 'avoid'}}>
                {item?.nome.length > 0 &&
                  <div style={{ width: '100%', backgroundColor: 'rgb(204, 204, 204,0.5)', borderRadius: '6px 6px 0px 0px' }}>

                    <div style={{ borderRadius: 6, fontSize: 15, textAlign: 'center', color: '#092140', fontWeight: 'bold', height: 30 }} >{nomeProduto.toUpperCase()}</div>
                  </div>

                }

                <div style={{ overflowX: 'auto' }}>

                  <table key={index}  id="originalTable" style={{ marginBottom: 40,width:'100%' }}>
                    <thead>
                      <tr>



                      </tr>
                    </thead>
                    <tbody>
                      {(item?.nome.length > 0 && !item?.nome.every((element) => element === "")) &&
                        <tr style={{ backgroundColor: 'rgb(204, 204, 204,0.5)', padding: 0 }} >
                          <th style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16, padding: 5, borderTop: index == 0 ? 'none' : '1px solid #fff' }} >Produto</th>

                          {item && item.nome?.map((item2, index2) => (
                            <th key={`Nome-${index2}-${index}`} style={{ textAlign: 'center', fontWeight: 100, padding: 5, borderTop: index == 0 ? 'none' : '1px solid #fff' }} >{item2}</th>
                          ))}


                        </tr>
                      }
                      {item?.precoVida.length > 0 && !item?.precoVida.every((element) => element === "") &&
                        <tr style={{ backgroundColor: 'rgb(204, 204, 204,0.5)', padding: 0 }} >
                          <th style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16, padding: 5, borderTop: '1px solid #fff' }} >Preço/Vida</th>
                          {item && item?.precoVida?.map((item2, index2) => (
                            <th key={`Preco-${index2}-${index}`} style={{ textAlign: 'center', fontWeight: 100, padding: 5, borderTop: '1px solid #fff' }} >{item2 ? `R$ ${item2}` : ''}</th>
                          ))}
                        </tr>
                      }

                      {item?.cobertura.length > 0 && !item?.cobertura.every((element) => element === "") &&
                        <tr style={{ backgroundColor: 'rgb(204, 204, 204,0.5)' }}>
                          <th style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16, padding: 5, borderTop: '1px solid #fff' }} >Cobertura</th>
                          {item && item?.cobertura?.map((item2, index2) => (
                            <th key={`cobertura-${index2}-${index}`} style={{ textAlign: 'center', fontWeight: 100, padding: 5, borderTop: '1px solid #fff' }} >{item2}</th>
                          ))}
                        </tr>
                      }
                      {item?.acomodacao.length > 0 && !item?.acomodacao.every((element) => element === "") &&
                        <tr style={{ backgroundColor: 'rgb(204, 204, 204,0.5)' }}>
                          <th style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16, padding: 5, borderTop: '1px solid #fff' }} >Acomodação</th>
                          {item && item?.acomodacao?.map((item2, index2) => (
                            <th key={`acomodacao-${index2}-${index}`} style={{ textAlign: 'center', fontWeight: 100, padding: 5, borderTop: '1px solid #fff' }} >{item2}</th>
                          ))}
                        </tr>
                      }
                      {item?.coparticipacao?.length > 0 && !item?.coparticipacao.every((element) => element == "") &&
                        <tr style={{ backgroundColor: 'rgb(204, 204, 204,0.5)' }}>
                          <th style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 16, padding: 5, borderTop: '1px solid #fff' }} >Coparticipação</th>
                          {item && item?.coparticipacao?.map((item2, index2) => (
                            <>
                              {item2.nome &&
                                <th key={`Coparticipacao-${index2}-${index}`} style={{ textAlign: 'center', fontWeight: 100, padding: 5, borderTop: '1px solid #fff' }} >{item2.status ? '' + `${item2.nome.includes('Parcial') ? ' Parcial' : ''}` + `${item2.nome.includes('Total') ? ' Total' : ''}` + `${(item2.nome.includes('Sim') && !item2.percentual) ? ' Sim' : ''}` : 'Sem coparticipacão'}  {(item2.status && item2.percentual) ? 'de ' + item2.percentual + '%' : ''} </th>
                              }
                            </>
                          ))}
                        </tr>
                      }
                 
                      {  item.precoVida.every(item => item === '') && item?.faixas.length > 0 ? item?.faixas?.map((item4, cont1) => (
                        <>
                          {item4.map((item5, cont2) => (
                            <tr key={`Faixa-${cont1}-${cont2}`} >
                              <th style={{ textAlign: 'center', borderTop: '1px solid #fff' }} >{item5.nome.replace('-', ' à ')}</th>
                              {item5.valor.map((item6, cont3) => (
                                <th key={`Faixas-${cont1}-${cont2}-${cont3}`} style={{ textAlign: 'center', borderTop: '1px solid #fff' }} >
                                  {item5.status != 1 ?
                                    <span style={{ color: 'red' }}>
                                      {item5.status == 2 ? 'Comercialização suspensa' : 'Em Atualização'}
                                    </span>
                                    :
                                    <>
                                      {item6.status ? `${(parseFloat(item6.valor))?.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}` : 'Suspenso'}
                                    </>
                                  }
                                </th>
                              ))}
                            </tr>
                          ))}
                        </>
                      )) : ''}
                    </tbody>
                  </table>
                </div>
              </div>
            ))}



            {info?.observacoes?.length > 0 &&
              <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                <div style={{ breakInside: 'avoid', border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                  <p style={{ breakInside: 'avoid', textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', marginTop: '-16px' }}>Observações Importantes</p>
                  <p style={{ fontSize: 14, color: 'red', textAlign: 'center', lineHeight: 1.3 }}>"Esta simulação poderá variar de acordo com o perfil do cliente e a critério da operadora.<br /> Preços, condições e critérios de aceitação da proposta estão sujeitos a analise e confirmação no momento da implantação do contrato."</p>
                  <Col lg={12}>
                    <div className='form-control' style={{ border: 'none', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }} >
                      {info && info?.observacoes?.map((item, index) => (
                        <>
                          <p>
                            <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                          </p>

                          <p style={{ fontSize: 16, marginBottom: 10 }}>
                            <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                          </p>
                        </>
                      ))}
                    </div>
                  </Col>
                </div>
              </div>
            }

            {info?.rede?.length > 0 &&
              <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                <div style={{ breakInside: 'avoid', border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                  <p style={{ breakInside: 'avoid', textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff',  marginTop: '-16px' }}>Rede de Atendimento</p>
                  <Col lg={12}>
                    <div className='form-control' style={{ border: 'none', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                      {info && info?.rede?.map((item) => (
                        <>
                          <p>
                            <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                          </p>
                          <p style={{ fontSize: 16, marginBottom: 10 }}>
                            <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                          </p>
                        </>
                      ))}
                    </div>
                  </Col>
                </div>
              </div>
            }
            {info?.coparticipacoes?.length > 0 &&
              <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                <div style={{ breakInside: 'avoid', border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                  <p style={{ breakInside: 'avoid', textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff',  marginTop: '-16px' }}>Coparticipação</p>

                  <Col lg={12}>
                    <div className='form-control' style={{ border: 'none', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }} >
                      {info && info?.coparticipacoes?.map((item, index) => (
                        <>
                          <p>
                            <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                          </p>
                          <p style={{ fontSize: 16, marginBottom: 10 }}>
                            <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                          </p>
                        </>
                      ))}
                    </div>
                  </Col>
                </div>
              </div>
            }
            {info?.reembolso?.length > 0 &&
              <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                <div style={{ breakInside: 'avoid', border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                  <p style={{ breakInside: 'avoid', textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', marginTop: '-16px' }}>Diferenciais e Reembolsos</p>
                  <Col lg={12}>
                    <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                      {info && info?.reembolso?.map((item) => (
                        <>
                          <p style={{ fontWeight: 'bold' }} >
                            {item?.produto.join(' / ')}
                          </p>
                          <p style={{ fontSize: 16, marginBottom: 10 }}>
                            <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                          </p>
                        </>
                      ))}
                    </div>
                  </Col>
                </div>
              </div>
            }
            {info?.carencia?.length > 0 &&
              <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                <div style={{ breakInside: 'avoid', border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                  <p style={{ breakInside: 'avoid', textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', marginTop: '-16px' }}>Carências</p>
                  <Col lg={12}>
                    <div className='form-control' style={{ border: "none", borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                      {info && info?.carencia?.map((item) => (
                        <>
                          <p>
                            <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                          </p>
                          <p style={{ fontSize: 16, marginBottom: 10 }}>
                            <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                          </p>
                        </>

                      ))}

                    </div>
                  </Col>
                </div>
              </div>
            }



            {info?.dependente?.length > 0 &&
              <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                <div style={{ breakInside: 'avoid', border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                  <p style={{ breakInside: 'avoid', textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', marginTop: '-16px' }}>Dependentes</p>
                  <Col lg={12}>
                    <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}   >
                      {info && info?.dependente?.map((item) => (
                        <>
                          <p>
                            <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                          </p>
                          <p style={{ fontSize: 16, marginBottom: 10 }}>

                            <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                          </p>
                        </>

                      ))}

                    </div>
                  </Col>
                </div>
              </div>
            }


            {info?.documento?.length > 0 &&
              <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                <div style={{ breakInside: 'avoid', border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                  <p style={{ breakInside: 'avoid', textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', marginTop: '-16px' }}>Documentos Necessários</p>
                  <Col lg={12}>
                    <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                      {info && info?.documento?.map((item) => (
                        <>
                          <p>
                            <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                          </p>
                          <p style={{ fontSize: 16, marginBottom: 10 }}>
                            <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                          </p>
                        </>


                      ))}

                    </div>
                  </Col>
                </div>
              </div>
            }

            {info?.pagamento?.length > 0 &&
              <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                <div style={{ breakInside: 'avoid', border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                  <p style={{ breakInside: 'avoid', textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', marginTop: '-16px' }}>Formas de Pagamentos</p>
                  <Col lg={12}>
                    <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                      {info && info?.pagamento?.map((item) => (
                        <>
                          <p>
                            <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                          </p>
                          <p style={{ fontSize: 16, marginBottom: 10 }}>
                            <div dangerouslySetInnerHTML={{ __html: item.item.obs?.replace(/font-family:[^;]*;/g, '')?.replace(/background-color:[^;]*;/g, '')?.replace(/font-size:[^;]*;/g, '') }}  ></div>
                          </p>
                        </>

                      ))}

                    </div>
                  </Col>
                </div>
              </div>
            }

            {info?.entidades?.length > 0 &&
              <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                <div style={{ breakInside: 'avoid', border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                  <p style={{ breakInside: 'avoid', textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', marginTop: '-16px' }}>Entidades e Profissões  </p>
                  <Col lg={12}>
                    <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >
                      {info && info?.entidades?.map((item) => (
                        <div key={item} style={{ marginBottom: 20 }}>
                          <p>
                            <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                          </p>

                          <span>{item.item.gruposentidades.map((item4) => (

                            <span key={item4}><span style={{ color: '#092140', fontWeight: 'bold', fontSize: 16 }}>{item4?.entidade?.nome}:</span>  {item4.entidadesprofisso?.profisso?.nome} - </span>

                          ))}</span>

                        </div>

                      ))}

                    </div>
                  </Col>
                </div>
              </div>
            }










            {info?.areacomercializacao?.length > 0 &&
              <div style={{ breakInside: 'avoid', paddingTop: 20 }}>
                <div style={{ breakInside: 'avoid', border: "1px solid #dddada", margin: 15, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 8 }}>
                  <p style={{ breakInside: 'avoid', textAlign: 'center', fontSize: 15, textTransform: 'uppercase', backgroundColor: '#fff', marginTop: '-16px' }}>Áreas de Comercialização e Atendimento  </p>
                  <Col lg={12}>
                    <div className='form-control' style={{ border: 'none', borderColor: '#E9E9EF', borderWidth: 1, lineHeight: 1.5, fontSize: 16, height: '100%' }}  >

                      {info && info?.areacomercializacao?.map((item) => (
                        <div key={item} style={{ marginBottom: 20 }} >
                          <p>
                            <span style={{ fontWeight: 'bold', color: '#092140' }}>Produtos:</span> <span style={{ fontSize: 12 }} >{item?.produto.join(' | ')}</span>
                          </p>


                          {item?.municipios.filter((item, index, self) => index === self?.findIndex((m) => m.idesta === item.idesta)).map((muni) => (
                            <>
                              {!info?.areacomercializacao[0].descricoes?.some((element) => (Number(element?.idestado) == muni.idesta && element.descricao)) ?

                                <>
                                  {item?.municipios.map((item3, index) => (
                                    <>
                                      <span key={`Comercializacao-${index}`} dangerouslySetInnerHTML={{ __html: `${decodeURIComponent(item3?.munici)} -` }}  ></span>
                                    </>
                                  ))}
                                </>
                                :
                                <>
                                  <span key={`Comercializacao-`}  >{info?.areacomercializacao[0].descricoes.find((element) => Number(element.idestado) == muni.idesta)?.descricao}</span><br />
                                  <a style={{ textDecoration: 'underline', textAlign: 'justify' }} onClick={() => { handleMunicipios(item?.municipios) }} > Ver municípios</a>
                                </>
                              }
                            </>
                          ))

                          }




                          <br />
                          <p  >{item.descricao}</p>

                        </div>

                      ))}

                    </div>
                  </Col>
                </div>
              </div>
            }




          </div>


        </>}
        <div className="feature-btn text-center mt-20"></div>
      </div>


      <Modal isOpen={modalMunicipios} centered={true}>
        <div className="modal-header" style={{ border: 'none' }}>

          <ExitModal setModal={setModalMunicipios} />
        </div>
        <div className="modal-body" style={{ paddingLeft: 30, paddingRight: 30, paddingBottom: 20 }}>
          <p style={{ color: '#092140', textAlign: 'center' }}>Municípios</p>

          <Row style={{ justifyContent: 'center', alignItems: 'center' }} >
            {municipios.map((item3, index) => (
              <span key={`Comercializacao-${index}`} dangerouslySetInnerHTML={{ __html: `${decodeURIComponent(item3.munici)} -` }}  ></span>
            ))}
          </Row>
        </div>

      </Modal>
    </section>
  );
}

export default VisualizarTabela;

import axios from 'axios'
import { baseUrlApi } from './baseUrl'
const token = localStorage.getItem("authToken");

const axiosInstance = axios.create({
    baseURL: `${baseUrlApi}/auth`,
    headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
    }
})

export const Api = {
    Acessar: async () => {
        let response = await axiosInstance.post('/')
        if (token &&  response?.data?.error) {
         //   localStorage.removeItem('authUser')
            return true
        }else{
            return false
        }
    },
}
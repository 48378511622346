import React, { useEffect, useRef, useState } from "react";
import { HiOutlineX } from "react-icons/hi";

export const Exit = ({ modal ,setModal,top}) => {  
    return (

        <div style={{ top: top?top:10, right: 10, zIndex: 9999, position: "absolute", backgroundColor: `rgba(255,255,255,0.6)`, display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '50%',width:20,height:20 }} >
            <button type="button" onClick={() => { setModal(false); }} className="close" data-dismiss="modal" aria-label="Close" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }} >
                <HiOutlineX size={15} />
            </button>
        </div>

    )
}
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Row } from "reactstrap";
import { Api as ApiAuth } from "../../../api/apiAuthentica";
import { Api } from "../../../api/apiUsuario";
import logo from "../../../assets/images/logos/logo.png";
import logoAqui from "../../../assets/images/sualogoaqui.png";
import StickyMenu from "../../../lib/StickyMenu";
import Navigation from "../../../components/helpers/Navigation";
import MobileHeaderCom from "./Mobile";
import { Login } from "./Login";
import { MeusDados } from "./MeusDados";
import { useNavigate } from 'react-router-dom';
import { v4 as uuidV4 } from "uuid"
import { baseUrlApi, baseUrlApp } from "../../../api/baseUrl";
import { ExitModal } from "../../components/exitModal";
import { useCookies } from "react-cookie";
export default function HeaderHomeThree() {
  useEffect(() => {
    StickyMenu(".main-header");
  });
  const [cookies, setCookie] = useCookies();
  const [modalLogin, setModalLogin] = useState();
  const [modalDados, setModalDados] = useState();
  const [modalApagar, setModalApagar] = useState();
  const [cookie] = useCookies(['authUser'])
  const obj =cookie.authUser
  useEffect(() => {
    const id = obj?.id
    if (!id) {
      setCookie('id', JSON.stringify(uuidV4()), { path: "/", maxAge: 365 * 24 * 60 * 60, sameSite: "strict", secure: true });

      // localStorage.setItem("id", JSON.stringify(uuidV4()));
    }

  }, [])
  const deletar = async () => {
    let excluir = await Api.deletar({ id: obj?.id })
    setCookie('authUser', "{}", { path: "/", maxAge: 365 * 24 * 60 * 60, sameSite: "strict", secure: true });
    // localStorage.setItem("authUser", "{}");
    window.location.href = '/'
  }
  const handleLink = () => {
    window.open(`${baseUrlApp}/login/${obj?.id}/${obj?.token}`, "_blank");

  }
  const [lastScrollTop, setLastScrollTop] = useState(0);
  const [fixed, setFixed] = useState(false)
  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.pageYOffset || document.documentElement.scrollTop;

      if (currentScrollTop > lastScrollTop) {
        setFixed(false)
      } else {
        setFixed(true)
      }

      setLastScrollTop(currentScrollTop <= 0 ? 0 : currentScrollTop);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [lastScrollTop]);
  const navigate = useNavigate();
  const auth = async () => {
    let response = await ApiAuth.Acessar()
    if(response){
      setCookie('authUser', {})
      navigate('/')
    }
  }
  useEffect(() => {
    auth()
  }, [])

  return (
    <>
      <header className="main-header header-three text-white" >
        <div className="header-upper" style={{ background: 'none', boxShadow: "none", border: 'none', position: fixed ? 'fixed' : '' }}>
          <div className="container clearfix" style={{ boxShadow: '0px 4px 4px 0px #0921400D', marginTop: 5, backgroundColor: '#fff' }}>
            <MobileHeaderCom logo={logo} />
          </div>
        </div>
      </header>

      <header className="main-header header-three text-white" >
        <div className="header-upper" style={{ display: 'none' }}>
          <div className="container clearfix">
            <MobileHeaderCom logo={logo} />
            <div className="header-inner d-flex align-items-center"  >
              <div className="logo-outer">
                <div className="logo">
                  <a href="/">
                    <img src={logo} alt="Logo" title="Logo" style={{ width: "35%", marginRight: 10 }} />
                    <img src={obj?.foto ? `${baseUrlApi}/imagens/${obj?.foto}` : logoAqui} alt="Logo" title="Logo" style={{ width: "35%" }} />
                  </a>
                </div>
              </div>
              <div className="nav-outer clearfix d-flex align-items-center" >
                <div className="main-menu navbar-expand-lg">
                  <Navigation />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal id="modal-center3" size="md" centered={true} isOpen={modalApagar}>
          <div className="modal-header">
            <h5 className="modal-title mt-0">Apagar conta</h5>
            <ExitModal setModal={setModalApagar} />
          </div>
          <div className="modal-body">
            <Row>
              <p style={{ color: 'red', marginLeft: 15 }} >Tem certeza que deseja excluir sua conta!</p>
            </Row>
          </div>
          <div className="modal-footer" style={{ display: 'block' }} >
            <button className="btn btn-danger" style={{ float: 'left', marginRight: 20 }} onClick={() => {
              setModalApagar(false);
            }} >Fechar</button>
            <button className="btn btn-success" style={{ float: 'right' }} onClick={deletar} >Excluir</button>
          </div>
        </Modal>
        <Login modalLogin={modalLogin} setModalLogin={setModalLogin} />
        <MeusDados modalDados={modalDados} setModalDados={setModalDados} />
      </header>
    </>
  );
}
